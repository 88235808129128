import React, { Component } from "react";
import i18next from "i18next";
import Swal from "sweetalert2";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "antd/dist/antd.css";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";

import API from "../../../../utils/API";

const lang = localStorage.getItem("lang") || "en";

class AdminDraft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managelistings: [],
      status: [],
      agencies: [],
      basic_available: 0,
      featured_available: 0,
      premium_available: 0,
      rent_count: 0,
      buy_count: 0,
      all_count: 0,
      rent_button: false,
      buy_button: false,
      all_button: true,
      selected_button: 4,
      isLoading: false,
      propertytypes: [],
      locations: [],
      filterLocation: [],
      selectedLocations: [],
      purpose: 0,
      category_id: 0,
      agency_id: 0,
      beds: -1,
      select_purpose: 0,
      selectbtn: 0,
      selectbtnstr: "",
      dateFrom:  "",
      dateTo:  "",
    };
  }
  componentDidMount() {
    API.get("/allpropertytypes")
    .then((response) => {
      this.setState({
        propertytypes: response.data.propertytypes,
      });
      //this.setState({categories:response.data.categories})
    })
    .catch();
  API.get("/admindraftlocation")
    .then((response) => {
      console.log("all loca", response.data);
      this.setState({
        filterLocation:
          lang == "en" ? response.data.locations : response.data.locations_ar,
      });
    })

    .catch((e) => console.log("error"));

  // agencies
  API.get("/listagency")
    .then((response) => {
      this.setState({ agencies: response.data.agencies });
    })

    .catch((e) => console.log("error"));
    const page = 1;
    const perPage = 10;
    const data = {
        selected_button: 4,
        selectedLocations: [],
        purpose: 0,
        select_purpose: 0,
        category_id: 0,
        beds: -1,
      };

    this.fetchData(page, perPage,data);
  }
  fetchData = (page, perPage,data) => {
    
    this.setState({ isLoading: true });
    API.post(`/adminfilterdraft?page=${page}&perPage=${perPage}`,data)
    .then(async (response) => {
    var data = await response.data.managelistings.data;
        var i;
        for (i = 0; i < data.length; i++) {
          if (data[i].package_type == 1) {
            data[i].basicbutton = true;
          } else {
            data[i].basicbutton = false;
          }
          if (data[i].package_type == 2) {
            data[i].featuredbutton = true;
          } else {
            data[i].featuredbutton = false;
          }
          if (data[i].package_type == 3) {
            data[i].premiumbutton = true;
          } else {
            data[i].premiumbutton = false;
          }
        }
        await response.data.managelistings;
        this.setState({
          managelistings: response.data.managelistings,
          status: response.data.status,
          isLoading: false,
          active_count: response.data.active_count,
          in_active_count: response.data.in_active_count,
          rent_count: response.data.rent_count,
          buy_count: response.data.buy_count,
          draft_count: response.data.draft_count,
          all_count: response.data.all_count,
        });

      })
      .catch((e) => console.log("error"));
   
  };
  handelDelete = (managelisting, e) => {
    e.preventDefault();
    if (!managelisting || managelisting < 1) {
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          API.delete("/propertiesdeleted/" + managelisting)
            .then((response) => {
              toast.success(" successfully deleted")
              const data = {
                selected_button: this.state.selected_button,
                selectedLocations: this.state.selectedLocations,
                purpose: this.state.purpose,
                select_purpose: this.state.select_purpose,
                category_id: this.state.category_id,
                beds: this.state.beds,
              };
              this.fetchData(1,10,data)
            })
            .catch((err) => console.log(err));
        }
      });
    }
  };

  
  handleAll = (e) => {
    this.setState({
        selected_button: 4,
        buy_button: false,
        rent_button: false,
        all_button: true,
        dateFrom:"",
        dateTo:""
      });
      const data = {
        selected_button: 4,
        selectedLocations: this.state.selectedLocations,
        purpose: this.state.purpose,
        select_purpose: this.state.select_purpose,
        category_id: this.state.category_id,
        beds: this.state.beds,
      };
      this.fetchDataBy(data);
  };

  handleRent = (e) => {
    this.setState({selected_button:5,buy_button:false,rent_button:true,all_button:false,dateFrom:"",dateTo:""})
    const data = {selected_button:5,selectedLocations:this.state.selectedLocations,purpose:this.state.purpose,select_purpose:this.state.select_purpose,category_id:this.state.category_id,beds:this.state.beds}
    this.fetchDataBy(data)
  };
  handleSale = (e) => {
    this.setState({selected_button:6,buy_button:true,rent_button:false,all_button:false,   dateFrom:"",dateTo:""})
    const data = {selected_button:6,selectedLocations:this.state.selectedLocations,purpose:this.state.purpose,select_purpose:this.state.select_purpose,category_id:this.state.category_id,beds:this.state.beds}
    this.fetchDataBy(data)
  };

  fetchDataBy = (data) => {
    const page = 1;
    const perPage = 10;
    this.setState({isLoading:true})
    this.fetchData(page, perPage, data);
  };
  handleDateFrom = (e) => {
    this.setState({ dateFrom: e });
  };

  handleDateTo = (e) => {
    this.setState({ dateTo: e });
  };

  handleSearch = (e) => {
    const page = 1;
    const perPage = 10;

    this.setState({isLoading:true})
    const data = {
        selected_button:this.state.selected_button,
        selectedLocations:this.state.selectedLocations,
        purpose:this.state.purpose,category_id:this.state.category_id,
        beds:this.state.beds,select_purpose:this.state.select_purpose,
        agency_id:this.state.agency_id,
        dateFrom:this.state.dateFrom,dateTo:this.state.dateTo
    }
    this.fetchData(page, perPage, data);
  };

  handlePurpose = (e) => {
    e.preventDefault();
    this.setState({ select_purpose: e.target.value });
  };

  handleCategory = (e) => {
    e.preventDefault();
    this.setState({ category_id: e.target.value });
  };

  handleAgency = (e) => {
    e.preventDefault();
    this.setState({ agency_id: e.target.value });
  };

  handleBedroom = (e) => {
    e.preventDefault();
    this.setState({ beds: e.target.value });
  };

  handleStatus = (st, managelisting, e) => {
    //return
    let index = this.state.managelistings.data.indexOf(managelisting);


    if (st.id == 6) {
      (async () => {
        const { value: formValues } = await Swal.fire({
          title: "Reject description",
          html:
            'English:<input id="swal-input1" class="swal2-input">' +
            'Arabic:<input id="swal-input2" class="swal2-input">',
          focusConfirm: false,
          preConfirm: () => {
            return [
              document.getElementById("swal-input1").value,
              document.getElementById("swal-input2").value,
            ];
          },
        });

        if (formValues) {
          if (formValues[0] == "") return;

          const data = {
            property_id: managelisting.id,
            status_id: st.id,
            rejectdescription: formValues,
          };
          API.post("/updatePropertyStatus", data)
            .then((response) => {
              //return
              if (response.data.success) {
                var mydata = this.state.managelistings.data;
                var i;
                for (i = 0; i < mydata.length; i++) {
                  if (i == index) {
                    mydata[i].status_id = st.id;
                    mydata[i].status_en = st.status_en;
                    mydata[i].status_ar = st.status_ar;
                  }
                }

                this.setState({ managelistings: mydata });
              }

              //this.setState({managelistings: data,isLoading:false})
            })
            .catch((e) => console.log("error"));
        }
      })();
    } else {
      const data = {
        property_id: managelisting.id,
        status_id: st.id,
        rejectdescription: [],
      };

      API.post("/updatePropertyStatus", data)
        .then((response) => {
          if (response.data.success) {
            var mydata = this.state.managelistings;
            var i;
            for (i = 0; i < mydata.length; i++) {
              if (i == index) {
                mydata[i].status_id = st.id;
                mydata[i].status_en = st.status_en;
                mydata[i].status_ar = st.status_ar;
              }
            }

            this.setState({ managelistings: mydata });
          }

          //this.setState({managelistings: data,isLoading:false})
        })
        .catch((e) => console.log("error"));
    }

    //console.log("data before ",data)
  };

  render() {
    const columns = [
      {
        title: i18next.t("agencyname"),
        render: (text, record) => (
          <>{lang == "en" ? record.name_en : record.name_ar}</>
        ),
      },

      //

      {
        title: lang == "en" ? "Type" : "نوع العقار",
        render: (text, record) => (
          <>{lang == "en" ? record.typeName_en : record.typeName_ar}</>
        ),
      },
      {
        title: lang == "en" ? "Purpose" : "الغرض",
        render: (text, record) => (
          <>
            {record.purpose == 1 ? i18next.t("forrent") : i18next.t("forbuy")}
          </>
        ),
      },
      {
        title: lang == "en" ? "Location" : "الموقع",
        render: (text, record) => (
          <>
            {lang == "en"
              ? record.emirate_en + "-" + record.area_en
              : record.emirate_ar + "-" + record.area_ar}
          </>
        ),
      },
      {
        title: lang == "en" ? "Price" : "السعر",
        dataIndex: "price",
        sorter: (a, b) => a.price.length - b.price.length,
      },
      {
        title: lang == "en" ? "Beds" : "الغرف",
        render: (text, record) => (
          <>
            {record.beds > -1
              ? record.beds > 0
                ? record.beds
                : "Studio"
              : null}
          </>
        ),
        // dataIndex: 'beds',
        //  sorter: (a, b) => a.beds.length - b.beds.length,
      },
      {
        title: lang == "en" ? "Listed By" : "بواسطة",
        dataIndex: "agent_en",
        sorter: (a, b) => a.agent_en.length - b.agent_en.length,
      },
      {
        title: i18next.t("status"),
        render: (text, record) => (
          <div className="dropdown">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {record.status_id == 1 ? (
                <span className="badge light badge-danger">
                  {lang == "en" ? record.status_en : record.status_ar}
                </span>
              ) : null}
              {record.status_id == 2 ? (
                <span className="badge light badge-warning">
                  {lang == "en" ? record.status_en : record.status_ar}
                </span>
              ) : null}
              {record.status_id == 3 ? (
                <span className="badge light  badge-secondary">
                  {lang == "en" ? record.status_en : record.status_ar}
                </span>
              ) : null}
              {record.status_id == 4 ? (
                <span className="badge light  badge-success">
                  {lang == "en" ? record.status_en : record.status_ar}
                </span>
              ) : null}
              {record.status_id == 5 ? (
                <span className="badge light  badge-danger">
                  {lang == "en" ? record.status_en : record.status_ar}
                </span>
              ) : null}
              {record.status_id == 6 ? (
                <span className="badge light  badge-danger">
                  {lang == "en" ? record.status_en : record.status_ar}
                </span>
              ) : null}
              {record.status_id == 7 ? (
                <span className="badge light  badge-danger">
                  {lang == "en" ? record.status_en : record.status_ar}
                </span>
              ) : null}
            </a>

            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              {this.state.status.length > 0 &&
                this.state.status.map((st) => (
                  <li
                    key={st.id}
                    onClick={this.handleStatus.bind(this, st, record)}
                  >
                    <a className="dropdown-item">{st.status_en}</a>
                  </li>
                ))}
            </ul>
          </div>
        ),
      },

      {
        title: i18next.t("actions"),
        render: (text, record) => (
          <div className="ms-auto">
            <Link
              to={"admineditlist/" + record.id}
              className="btn btn-primary btn-sm m-2"
            >
              <i className="bi bi-pencil-square"></i>
            </Link>
           
            <a
              className="btn btn-danger btn-sm m-2"
              onClick={this.handelDelete.bind(this, record.id)}
            >
              <i className="bi bi-trash"></i>
            </a>
          </div>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
        const data = {
            selected_button: this.state.selected_button,
            selectedLocations: this.state.selectedLocations,
            purpose: this.state.purpose,
            select_purpose: this.state.select_purpose,
            category_id: this.state.category_id,
            beds: this.state.beds,
          };

        this.fetchData(pagination.current, pagination.pageSize,data);
      };
    return (
      <div>
        <div className="container-fluid">
          <Toaster />
          <div className="row mb-4">
            <div className="card">
              <div className="col-md-12">
                <div className="card-header">
                  <h4 className="card-title">Inactive / Deleted</h4>
                </div>
                <div className="card-body">
                  <div className="mb-5">
                    <button
                      onClick={this.handleAll}
                      type="button"
                      className={
                        this.state.all_button
                          ? "btn btn-outline-success btn-sm"
                          : "btn btn-outline-default btn-sm"
                      }
                      style={{
                        color: this.state.all_button ? "#33be8b" : "#fff",
                        backgroundColor: this.state.all_button
                          ? ""
                          : "lightgrey",
                      }}
                    >
                      {i18next.t("all")}{" "}
                      <span
                        className={
                          this.state.all_button
                            ? "badge bg-success"
                            : "badge bg-default"
                        }
                        style={{
                          backgroundColor: this.state.all_button
                            ? "green"
                            : "#fff",
                          color: this.state.all_button ? "#fff" : "#000",
                        }}
                      >
                        {this.state.all_count}
                      </span>
                    </button>
                    <button
                      onClick={this.handleSale}
                      type="button"
                      className={
                        this.state.buy_button
                          ? "btn btn-outline-success btn-sm"
                          : "btn btn-outline-default btn-sm"
                      }
                      style={{
                        color: this.state.buy_button ? "#33be8b" : "#fff",
                        backgroundColor: this.state.buy_button
                          ? ""
                          : "lightgrey",
                      }}
                    >
                      {i18next.t("sale")}{" "}
                      <span
                        className={
                          this.state.buy_button
                            ? "badge bg-success"
                            : "badge bg-default"
                        }
                        style={{
                          backgroundColor: this.state.buy_button
                            ? "green"
                            : "#fff",
                          color: this.state.buy_button ? "#fff" : "#000",
                        }}
                      >
                        {this.state.buy_count}
                      </span>
                    </button>
                    <button
                      onClick={this.handleRent}
                      type="button"
                      className={
                        this.state.rent_button
                          ? "btn btn-outline-success btn-sm"
                          : "btn btn-outline-default btn-sm"
                      }
                      style={{
                        color: this.state.rent_button ? "#33be8b" : "#fff",
                        backgroundColor: this.state.rent_button
                          ? ""
                          : "lightgrey",
                      }}
                    >
                      {i18next.t("rent")}{" "}
                      <span
                        className={
                          this.state.rent_button
                            ? "badge bg-success"
                            : "badge bg-default"
                        }
                        style={{
                          backgroundColor: this.state.rent_button
                            ? "green"
                            : "#fff",
                          color: this.state.rent_button ? "#fff" : "#000",
                        }}
                      >
                        {this.state.rent_count}
                      </span>
                    </button>
                  </div>

                  <div className="row mt-10">
                    <div className="col-md-2">
                      <label>Location</label>
                      <Autocomplete
                        //multiple
                        options={this.state.filterLocation}
                        onChange={(event, newValue) => {
                          this.setState({ selectedLocations: newValue !=null?newValue:[]  });
                        }}
                        getOptionLabel={(option) => option.location}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            label=""
                            placeholder=""
                          />
                        )}
                      />
                    </div>
                    <div className="col-md-2">
                      <label>Purpose</label>
                      <select
                        onChange={this.handlePurpose}
                        className="form-select select2-container--default"
                        style={{ height: "2.75rem" }}
                        aria-label="form-select"
                        required
                      >
                        <option value="0">Any</option>
                        <option value="1">For Rent</option>
                        <option value="2">For Sale</option>
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>Category</label>
                      <select
                        onChange={this.handleCategory}
                        className="form-select select2-container--default"
                        style={{ height: "2.75rem" }}
                        aria-label="form-select"
                        required
                      >
                        <option value="0">Any</option>
                        {this.state.propertytypes.map((propertytype) => (
                          <option key={propertytype.id} value={propertytype.id}>
                            {lang == "en"
                              ? propertytype.typeName_en
                              : propertytype.typeName_ar}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-3">
                      <label>Beds</label>
                      <select
                        className="form-select form-select-lg mb-3"
                        style={{ fontSize: "unset", height: "2.75rem" }}
                        aria-label="form-select-lg example"
                        onChange={this.handleBedroom}
                      >
                        <option value="-1">Any</option>
                        <option value="0">Studio</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                        <option value="15">15</option>
                        <option value="16">16</option>
                        <option value="17">17</option>
                        <option value="18">18</option>
                        <option value="19">19</option>
                        <option value="20">20</option>
                        <option value="20+">20+</option>
                      </select>
                    </div>

                    <div className="col-md-2">
                      <label>{lang == "en" ? "Agency" : "الشركة"}</label>
                      <select
                        onChange={this.handleAgency}
                        className="form-select select2-container--default"
                        style={{ height: "2.75rem" }}
                        aria-label="form-select"
                        required
                      >
                        <option value="0">Any</option>
                        {this.state.agencies.map((agency) => (
                          <option key={agency.id} value={agency.id}>
                            {lang == "en" ? agency.name_en : agency.name_ar}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mt-2 mb-3">
                    <div className="col-md-5">
                      <label>{lang == "en" ? "Date From" : "من تاريخ"}</label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateFrom}
                        onSelect={this.handleDateFrom} //when day is clicked
                        onChange={this.handleDateFrom} //only when value has changed
                      />
                    </div>
                    <div className="col-md-5">
                      <label> {lang == "en" ? "Date To" : " الي تاريخ"}</label>
                      <DatePicker
                        className="form-control"
                        selected={this.state.dateTo}
                        onSelect={this.handleDateTo} //when day is clicked
                        onChange={this.handleDateTo} //only when value has changed
                        format="yyyy-MM-dd"
                      />
                    </div>
                    <div className="col-md-1 mt-4">
                      <label style={{ marginTop: "30px" }}></label>
                      <button
                        type="button"
                        className="btn btn-sm btn-primary"
                        onClick={this.handleSearch}
                      >
                        {lang == "en" ? "Search" : "ابحث"}
                      </button>
                    </div>
                  </div>
                  <div className="mt-5" />

                  <hr />
                  <div class="table-responsive">
                  <Table
                                    onChange={handleTableChange}
                                    loading={this.state.isLoading}
                                    className="table-striped"
                                    pagination={{
                                      pageSizeOptions: ["10", "20", "50", "100"],
                                      total:
                                        this.state?.managelistings?.total > 0 &&
                                        this.state.managelistings.total,
                                      pageSize: this.state.managelistings.per_page,
                                      current: this.state.managelistings.current_page,
                                      showTotal: (total, range) =>
                                        `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                      showSizeChanger: true,
                                      onShowSizeChange: onShowSizeChange,
                                      itemRender: itemRender,
                                    }}
                                    style={{ overflowX: "auto" }}
                                    columns={columns}
                                   
                                    dataSource={
                                      this.state?.managelistings?.data
                                        ? this.state?.managelistings?.data
                                        : []
                                    }
                                    rowKey={(record) => record}
                                  />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminDraft;
