import React, { Component } from "react";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import API from "../../../utils/API";

import "antd/dist/antd.css";
import {Table,Breadcrumb} from 'antd'
import { ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons';
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import "../../antdstyle.css";
import { Grid } from "react-loader-spinner";

class AgencyLeads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;

    this.fetchData(page, perPage);
  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });
    API.get(`/agencyleads?page=${page}&perPage=${perPage}`)
      .then(async (response) => {
        await response.data.leads;

        this.setState({ isLoading: false, leads: response.data.leads });
      })
      .catch((e) => console.log("error"));
  };

  render() {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const lang = localStorage.getItem("lang") || "en";
    const columns = [
      {
        title: lang == "en" ? "Title" : "عنوان العقار",
        render: (text, record) => (
          <>{lang == "en" ? record.title_en : record.title_ar}</>
        ),
      },
      {
        title: lang == "en" ? "Location" : "الموقع",
        render: (text, record) => (
          <>
            {lang == "en"
              ? record.emirate_en + "-" + record.area_en
              : record.emirate_ar + "-" + record.area_ar}
          </>
        ),
      },
      {
        title: "Clicks",
        render: (text, record) => (
          <span class="badge bg-primary">{record.viewed.length}</span>
        ),
      },
      {
        title: "Calls",
        render: (text, record) => (
          <span class="badge bg-danger text-black">{record.call.length}</span>
        ),
      },
      {
        title: "Whatsapp",
        render: (text, record) => (
          <span class="badge bg-success text-black">
            {record.whatsapp.length}
          </span>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <div className="container-fluid">
        <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>
          {lang=="en"?<ArrowRightOutlined />:<ArrowLeftOutlined />}
          </Breadcrumb.Item>
          <Breadcrumb.Item>Leads</Breadcrumb.Item>
          
        </Breadcrumb>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  
                </div>
                <div className="card-body">
                  <div className="table-responsive">
              
                      <Table
                        className="table-striped"
                        onChange={handleTableChange}
                        loading={this.state.isLoading}
                        pagination={{
                          pageSizeOptions: ["10", "20", "50", "100"],
                          total:
                            this.state?.leads?.total > 0 &&
                            this.state.leads.total,
                          pageSize: this.state.leads.per_page,
                          current: this.state.leads.current_page,
                          showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                          showSizeChanger: true,
                          onShowSizeChange: onShowSizeChange,
                          itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto", textAlign: "right" }}
                        columns={columns}
                        // bordered
                        dataSource={
                          this.state?.leads?.data ? this.state?.leads?.data : []
                        }
                        rowKey={(record) => record}
                      />
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AgencyLeads;
