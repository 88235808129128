import React, { Component } from "react";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Table } from "antd";
import { itemRender, onShowSizeChange } from "../../../paginationfunction";
import "../../../antdstyle.css";
import { Grid } from "react-loader-spinner";

import API from "../../../../utils/API";

class Leads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
    };
  }

  componentDidMount() {
    const page = 1;
    const perPage = 10;
    this.fetchData(page, perPage);
  }

  fetchData = (page, perPage) => {
    this.setState({ isLoading: true });
    API.get(`/leads?page=${page}&perPage=${perPage}`)
      .then(async (response) => {
        await response.data.leads;

        this.setState({ isLoading: false, leads: response.data.leads });
      })
      .catch((e) => console.log("error"));
  };

  render() {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    const lang = localStorage.getItem("lang") || "en";
    const columns = [
      {
        title: lang == "en" ? "Company Name" : "اسم الشركة",
        render: (text, record) => (
          <>{lang == "en" ? record.name_en : record.name_ar}</>
        ),
      },

      {
        title: "Clicks",
        render: (text, record) => (
          <>
            {record.viewed[0].property_count > 0 ? (
              <Link to={"/clicks/" + record.id}>
                <span class="badge bg-primary">
                  {record.viewed[0].property_count}
                </span>
              </Link>
            ) : (
              " - "
            )}
          </>
        ),
      },
      {
        title: "Calls",
        render: (text, record) => (
          <>
            {record.call[0].property_count > 0 ? (
              <Link to={"/calls/" + record.id}>
                <span class="badge bg-danger text-black">
                  {record.call[0].property_count}
                </span>
              </Link>
            ) : (
              " - "
            )}
          </>
        ),
      },
      {
        title: "Whatsapp",
        render: (text, record) => (
          <>
            {record.whatsapp[0].property_count > 0 ? (
              <Link to={"/whatsapp/" + record.id}>
                <span class="badge bg-success text-black">
                  {record.whatsapp[0].property_count}
                </span>
              </Link>
            ) : (
              " - "
            )}
          </>
        ),
      },
    ];
    const handleTableChange = (pagination) => {
      this.fetchData(pagination.current, pagination.pageSize);
    };

    return (
      <div>
        <div className="container-fluid">
        
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">Leads</h4>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                     
                        <Table
                          className="table-striped"
                          loading={this.state.isLoading}
                          onChange={handleTableChange}
                          pagination={{
                            pageSizeOptions: ["10", "20", "50", "100"],
                            total:
                              this.state?.leads?.total > 0 &&
                              this.state.leads.total,
                            pageSize: this.state.leads.per_page,
                            current: this.state.leads.current_page,
                            showTotal: (total, range) =>
                              `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                          }}
                          style={{ overflowX: "auto", textAlign: "right" }}
                          columns={columns}
                          dataSource={
                            this.state?.leads?.data
                              ? this.state?.leads?.data
                              : []
                          }
                          rowKey={(record) => record}
                        />
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

export default Leads;
