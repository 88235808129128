import {
  LaptopOutlined,
  NotificationOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  DashboardFilled,
  UnorderedListOutlined,
  UsergroupDeleteOutlined,
  DownOutlined,
  LogoutOutlined,
  KeyOutlined,
  PlusOutlined,
  PhoneOutlined,
  FilePdfOutlined ,FundOutlined,StockOutlined,
  PlusCircleOutlined
} from "@ant-design/icons";
import i18next from "i18next";

import { Layout, Menu, Avatar, Dropdown, Space, Button, Drawer } from "antd";
import React, { useState, useRef, useEffect } from "react";
import AddProperty from "../../pages/Property/AddProperty";
import { store } from "../../store";
import Swal from "sweetalert2";

import { Link, useHistory, Redirect } from "react-router-dom";

import Categories from "../../pages/Admin/Settings/Categories/Categories";
import EditCategory from "../../pages/Admin/Settings/Categories/EditCategory";
import Dashboard from "../../Components/dashbaord/Dashboard";
import PrivateRoute from "../../Components/protectRoutes/PrivateRoute";
import AddNewUser from "../../pages/Agency/Usermanagement/AddNewUser";
import NewUser from "../../pages/Admin/Settings/Users/NewUser";
import Employees from "../../pages/Admin/Settings/Users/Employees";
import Agents from "../../pages/Agency/Usermanagement/Agents";
import EditEmployee from "../../pages/Admin/Settings/Users/EditEmployee";
import EditAgent from "../../pages/Agency/Usermanagement/EditAgent";
import Profile from "../../pages/auth/Profile";
import Managelisting from "../../pages/Agency/Listingmanagement/Managelisting";
import NewAgency from "../../pages/Admin/Settings/Agencies/NewAgency";
import ListAgencies from "../../pages/Admin/Settings/Agencies/ListAgencies";
import EditAgency from "../../pages/Admin/Settings/Agencies/EditAgency";
import ShowAgency from "../../pages/Admin/Settings/Agencies/ShowAgency";
import PropertyType from "../../pages/Admin/Settings/Type/PropertyType";
import EditPropertyType from "../../pages/Admin/Settings/Type/Edit";
import "./sidebar.css";
import "./Bye.css";

import EditProperty from "../../pages/Property/EditProperty";
import Listblogs from "../../pages/Blogs/Listblogs";
import Newblog from "../../pages/Blogs/Newblog";
import AgencyAgents from "../../pages/Admin/Settings/AgencyModule/AgencyAgents";
import AgencyProperties from "../../pages/Admin/Settings/AgencyModule/AgencyProperties";
import AgencyAgentsAndProperties from "../../pages/Admin/Settings/AgencyModule/AgencyAgentsAndProperties";
import ShowAgencyDetails from "../../pages/Admin/Settings/AgencyModule/ShowAgencyDetails";
import UsageList from "../../pages/Agency/Listingmanagement/UsageList";
import Notfound from "../../pages/errors/Notfound";
import AgentManagelisting from "../../pages/Agent/Listing/AgentManagelisting";
import Editblog from "../../pages/Blogs/Editblog";
import Listpages from "../../pages/Blogs/Pages/Listpages";
import Newpage from "../../pages/Blogs/Pages/Newpage";
import Editpage from "../../pages/Blogs/Pages/Editpage";
import Changepassword from "../../Components/Changepassword";
import AgentProperty from "../../pages/Property/AgentProperty";
import EditAgentProperty from "../../pages/Property/EditAgentProperty";
import AdminManagelist from "../../pages/Admin/Settings/Listing/AdminManagelist";
import SeoManagelisting from "../../pages/Admin/Settings/Seo/SeoManagelisting";
import AdminEditList from "../../pages/Admin/Settings/Listing/AdminEditList";
import SeoEditList from "../../pages/Admin/Settings/Seo/SeoEditList";
import AdminEditAgent from "../../pages/Admin/Settings/AgencyModule/AdminEditAgent";
import Pendinglisting from "../../pages/Agency/Listingmanagement/Pendinglisting";
import Publishlisting from "../../pages/Agency/Listingmanagement/Publishlisting";
import Rejectedlisting from "../../pages/Agency/Listingmanagement/Rejectedlisting";
import EditRejectedProperty from "../../pages/Property/EditRejectedProperty";
import AgentRejectedlisting from "../../pages/Agent/Listing/AgentRejectedlisting";
import AgentPublishlisting from "../../pages/Agent/Listing/AgentPublishlisting";
import AgentPendinglisting from "../../pages/Agent/Listing/AgentPendinglisting";
import EditRejectedAgentProperty from "../../pages/Property/EditRejectedAgentProperty";
import EditPublishProperty from "../../pages/Property/EditPublishProperty";
import EditPublishAgent from "../../pages/Property/EditPublishAgent";
import Draftlisting from "../../pages/Agency/Listingmanagement/Draftlisting";
import AgentDraftlisting from "../../pages/Agent/Listing/AgentDraftlisting";
import AdminDraft from "../../pages/Admin/Settings/Listing/AdminDraft";
import Leads from "../../pages/Admin/Settings/AgencyModule/Leads";
import Adminpending from "../../pages/Admin/Settings/Listing/Adminpending";

import Whatsapp from "../../pages/Admin/Settings/AgencyModule/Whatsapp";
import Click from "../../pages/Admin/Settings/AgencyModule/Click";
import Call from "../../pages/Admin/Settings/AgencyModule/Call";
import { useDispatch } from "react-redux";
import { baseurlImg } from "../BaseUrl";
import AgencyLeads from "../../pages/Agency/Listingmanagement/AgencyLeads";
import AgentLeads from "../../pages/Agent/Listing/AgentLeads";
import { MenuOutlined } from "@material-ui/icons";
import UseViewport from "../viewport/viewPort";
import AdminRejected from "../../pages/Admin/Settings/Listing/AdminRejected";
import AgentPrivate from "../protectRoutes/AgentPrivate";
import AgencyPrivate from "../protectRoutes/AgencyPrivate";
import AdminPrivate from "../protectRoutes/AdminPrivate";
import SeoPrivate from "../protectRoutes/SeoPrivate";
import Bye from "./Bye";
import AgencyLeadsReport from "../../pages/Agency/Listingmanagement/AgencyLeadsReport";
const { Header, Content, Sider, Footer } = Layout;

const lang = localStorage.getItem("lang") || "en";
const width = window.screen.width;
const menu1 = (
  <Menu
    items={[
      {
        key: "1",
        label: (
          <a className="dropdown-item" onClick={changeToEnglish}>
            <span className="flag-icon flag-icon-gb"> </span>{" "}
            {width > 720 ? "English" : ""}
          </a>
        ),
      },
      {
        key: "2",
        label: (
          <a className="dropdown-item" onClick={changeToArabic}>
            <span className="flag-icon flag-icon-ae"> </span>{" "}
            {width > 720 ? "العربية" : ""}
          </a>
        ),
      },
    ]}
  />
);

function changeToArabic(e) {
  e.preventDefault();
  const oldlang = localStorage.getItem("lang");
  if (oldlang != "ar") {
    localStorage.setItem("lang", "ar");
    window.location.reload();
  }
}
function changeToEnglish(e) {
  e.preventDefault();
  const oldlang = localStorage.getItem("lang");
  if (oldlang != "en") {
    localStorage.setItem("lang", "en");
    window.location.reload();
  }
}

const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map(
  (icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  }
);

const SidebarComponent = () => {
  const { width } = UseViewport();
  const dispatch = useDispatch();
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState([]);

  const handleMouseEnter = (key) => {
    setOpenKeys([key]);
  };

  const handleMouseLeave = () => {
    setOpenKeys([]);
  };
  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <Link to="/change-password">
              {lang == "en" ? "Change Password" : "تغير كلمة السر"}
            </Link>
          ),
          icon: <KeyOutlined />,
        },
        {
          key: "2",
          label: (
            <Link to="/profile">
              {lang == "en" ? "My Profile" : "  الملف الشخصي"}
            </Link>
          ),
          icon: <UserOutlined />,
        },
        {
          key: "3",
          danger: true,
          label: (
            <Link onClick={handleLogout}>
              {lang == "en" ? "Sign Out" : "  تسجيل الخروج"}
            </Link>
          ),
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );



  const [collapsed, setCollapsed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("top");
  const [going, setGoing] = useState(false);
  const userdata = store.getState((state) => state);
  let authenticated = userdata.auth.loggedIn;
  let role = userdata.auth.user.role;
  let user = userdata.auth.user;

  function handleLogout(e) {
    e.preventDefault();
   
    // Swal.fire({
    //   title: lang == "en" ? "Are you sure?" : "هل انت متأكد ؟",
    //   icon: "question",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   cancelButtonText: lang == "en" ? "Cancel" : "الغاء",
    //   confirmButtonText: lang == "en" ? "Yes , Sign out " : "نعم ، خروج",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    if(role==1){
      dispatch({ type: "SET_LOGOUT" });
      history.push("/");
    }else{
    let timerInterval;
    Swal.fire({
      title: lang == "en" ? "Sign Out" : "تسجيل الخروج",
      html:
        lang == "en"
          ? '<p class="animate-charcter">Thank you for choosing findproperties</p><br/>Session will close with in <b></b> seconds.'
          : '<p class="animate-charcter">شكراً لاختياركم فايند بروبرتيز</p><br/>الجلسة سوف تنتهي خلال <b></b> ثواني',
      timer: 10000,
      timerProgressBar: true,
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: lang == "en" ? "Cancel" : "الغاء",
      confirmButtonText: lang == "en" ? "Yes , Sign out " : "نعم ، خروج",

      //cancelButtonColor: '#d33',
      didOpen: () => {
        //Swal.showLoading();
        const b = Swal.getHtmlContainer().querySelector("b");
        timerInterval = setInterval(() => {
          b.textContent = (Swal.getTimerLeft() / 1000).toFixed(0);
        }, 100);
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: "SET_LOGOUT" });
        history.push("/");
      }
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
        dispatch({ type: "SET_LOGOUT" });
        history.push("/");
      }
    });
  }
    // dispatch({ type: "SET_LOGOUT" });
    // history.push("/");
    //}
    //});
    // dispatch({ type: "SET_LOGOUT" });
    // history.push("/");
    // setGoing(true);
    // setTimeout(() => {
    //setGoing(false);

    //}, 3000);
  }

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  // useEffect(() => {
  //   if (window.screen.width > 1000) {
  //     setShowHide(true);
  //   }
  // }, []);

  const items1 = ["1", "2", "3"].map((key) => ({
    key,
    label: React.createElement(
      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
      {
        className: "trigger",
        onClick: () => setCollapsed(!collapsed),
      }
    ),
  }));
  
  const NavItems = () => (
    <Menu
    theme="dark"
    mode="inline"
    defaultSelectedKeys={['1']}
    openKeys={openKeys}
    onOpenChange={(keys) => setOpenKeys(keys)}
    items={
      [
      {
        key: 'dashboard',
        icon: <DashboardFilled onClick={() => { setVisible(false); history.push('/dashboard'); }} />,
        label: (
          <Link   style={{ color: 'inherit', textDecoration: 'none' }} to="/dashboard" onClick={() => setVisible(false)}>
            {i18next.t('dashboard')}
          </Link>
        ),
        children:[]
        
      },
      role===1 &&{
        key: 'employees',
        icon: <UsergroupDeleteOutlined />,
        label:  <>{i18next.t("employees")}</>,
        children: [
          {
            key: 'emp',
            label: (
              <Link to="/employees" onClick={() => setVisible(false)}>
                   {lang == "en" ? " Employees" : "  الموظفين"}
              </Link>
            ),
          },
          {
            key: 'addemp',
            label: (
              <Link to="/addemp" onClick={() => setVisible(false)}>
               {lang == "en" ? "Add Employee" : "اضافة موظف"}
              </Link>
            ),
          },
         
        ], 
      },
      role === 1 && {
        key: 'manageList',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Manage List' : 'ادارة العقارات',
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/adminpending" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Pending' : 'عقارات جديدة'}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/adminrejected" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Rejected' : 'عقارات مرفوضة'}
              </Link>
            ),
          },
          {
            key: 'adminPublished',
            label: (
              <Link to="/adminlisting" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Published' : 'تم النشر'}
              </Link>
            ),
          },
          {
            key: 'adminDraft',
            label: (
              <Link to="/admindraft" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Drafted/Inactive' : 'عقارات محذوفة'}
              </Link>
            ),
          },
        ],
      },
      role === 1 && {
        key: 'agencies',
        icon: <UsergroupDeleteOutlined />,
        label: i18next.t('agencies'),
        children: [
          {
            key: 'manageAgency',
            label: (
              <Link to="/agencies" onClick={() => setVisible(false)}>
                {i18next.t('manageagency')}
              </Link>
            ),
          },
          {
            key: 'agencyAgents',
            label: (
              <Link to="/company-agents" onClick={() => setVisible(false)}>
                {i18next.t('agencyagents')}
              </Link>
            ),
          },
          {
            key: 'companyProperties',
            label: (
              <Link to="/company-properties" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Agency Properties' : 'عقارات الوكالة'}
              </Link>
            ),
          },
          {
            key: 'agentsProperties',
            label: (
              <Link to="/company-agents-properties" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Agents & Properties' : 'الوكلاء والعقارات'}
              </Link>
            ),
          },
        ],
      },
      role === 1 && {
        key: 'blogs',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Blogs and Pages' : ' المقالات والصفحات',
        children: [
          {
            key: 'blogs',
            label: (
              <Link to="/blogs" onClick={() => setVisible(false)}>
                 {lang == "en" ? "List Blogs " : " المقالات"}
              </Link>
            ),
          },
          {
            key: 'newblog',
            label: (
              <Link to="/newblog" onClick={() => setVisible(false)}>
                 {lang == "en" ? " New Blog" : "اضافة موضوع "}
              </Link>
            ),
          },
          {
            key: 'pages',
            label: (
              <Link to="/pages" onClick={() => setVisible(false)}>
                 {lang == "en" ? "List Pages" : " كل الصفحات"}
              </Link>
            ),
          },
          {
            key: 'newpage',
            label: (
              <Link to="/newpage" onClick={() => setVisible(false)}>
                 {lang == "en" ? " New Page" : " انشاء صفحة"}
              </Link>
            ),
          },
        ],
      },

      role ===1 &&{
        key: 'leads',
        icon: <StockOutlined onClick={() => { setVisible(false); history.push('/leads'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/leads`}  onClick={() => setVisible(false)}> {lang == "en" ? "Leads" : "حالات الاتصال"}</Link>,
        children:[]
    
      },
      role===2 &&{
        key: 'manageList',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Manage List' : 'ادارة العقارات',
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/pending" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Pending' : 'عقارات جديدة'}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/rejected" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Rejected' : 'عقارات مرفوضة'}
              </Link>
            ),
          },
          {
            key: 'adminPublished',
            label: (
              <Link to="/publish" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Published' : 'تم النشر'}
              </Link>
            ),
          },
          {
            key: 'adminDraft',
            label: (
              <Link to="/draft" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Drafted/Inactive' : 'عقارات محذوفة'}
              </Link>
            ),
          },
        ],
      },
      role ===2 &&{
        key: 'quota',
        icon: <FundOutlined onClick={() => { setVisible(false); history.push('/usage-quota'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/usage-quota`}  onClick={() => setVisible(false)}>{i18next.t("quotausage")}</Link>,
        children:[]
    
      },
      role===2 &&{
        key: 'users',
        icon: <UsergroupDeleteOutlined />,
        label: lang == "en" ? "User Management" : "ادارة المستخدمين",
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/agents" onClick={() => setVisible(false)}>
                {i18next.t("manageuser")}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/addnewuser" onClick={() => setVisible(false)}>
               {i18next.t("adduser")}
              </Link>
            ),
          },
         
        ], 
      },
      role ===2 &&{
        key: 'leads',
        icon: <StockOutlined onClick={() => { setVisible(false); history.push('/all-leads'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/all-leads`}  onClick={() => setVisible(false)}> {lang == "en" ? "Leads" : "حالات الاتصال"}</Link>,
        children:[]
    
      },

      role ===2 &&{
        key: 'newproperty',
        // icon: collapsed? <PlusCircleOutlined />:null,
        label: collapsed?<Link to={`/addproperty`}  onClick={() => setVisible(false)}><PlusCircleOutlined  /></Link>:      <Button
                      size="large"
                      shape="round"
                      type="primary"
                      style={{backgroundColor:'white',color:'black'}}
                    
                    >
         <Link to={`/addproperty`}  onClick={() => setVisible(false)}>{i18next.t("addproperty")}</Link>
         </Button>
    
      },

      // agent links
      role===3 &&{
        key: 'manageList',
        icon: <UnorderedListOutlined />,
        label: lang === 'en' ? 'Manage List' : 'ادارة العقارات',
        children: [
          {
            key: 'adminPending',
            label: (
              <Link to="/pending-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Pending' : 'عقارات جديدة'}
              </Link>
            ),
          },
          {
            key: 'adminRejected',
            label: (
              <Link to="/rejected-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Rejected' : 'عقارات مرفوضة'}
              </Link>
            ),
          },
          {
            key: 'adminPublished',
            label: (
              <Link to="/publish-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Published' : 'تم النشر'}
              </Link>
            ),
          },
          {
            key: 'adminDraft',
            label: (
              <Link to="/draft-property" onClick={() => setVisible(false)}>
                {lang === 'en' ? 'Drafted/Inactive' : 'عقارات محذوفة'}
              </Link>
            ),
          },
        ],
      },

      role ===3 &&{
        key: 'leads',
        icon: <StockOutlined onClick={() => { setVisible(false); history.push('/allleads'); }} />,
        label:  <Link   style={{ color: 'inherit', textDecoration: 'none' }} to={`/allleads`}  onClick={() => setVisible(false)}> {lang == "en" ? "Leads" : "حالات الاتصال"}</Link>,
        children:[]
    
      },
      role ===3 &&{
        key: 'newproperty',
        // icon: collapsed? <PlusCircleOutlined />:null,
        label: collapsed?<Link to={`/addproperty`}  onClick={() => setVisible(false)}><PlusCircleOutlined  /></Link>:      <Button
                      size="large"
                      shape="round"
                      type="primary"
                      style={{backgroundColor:'white',color:'black'}}
                    
                    >
         <Link to={`/add-property`}  onClick={() => setVisible(false)}>{i18next.t("addproperty")}</Link>
         </Button>
    
      },
      
    ]
  }
  />
    // <Menu
    //   width={200}
    //   style={{ height: "100vh" }}
    //   theme="dark"
    //   mode="inline"
    //   defaultSelectedKeys={["1"]}
    // >
    //   {role == 1 ? (
    //     <div style={{ marginLeft: "10px", marginRight: "10px" }}>
    //       <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //         <Link to={`/dashboard`}  onClick={() => setVisible(false)}>{i18next.t("dashboard")}</Link>
    //       </Menu.Item>
    //          <Menu.Item    icon={<UsergroupDeleteOutlined />}>
    //           <Link to={`/employees`}  onClick={() => setVisible(false)}>
    //             {lang == "en" ? " Employees" : "  الموظفين"}
    //           </Link>
    //         </Menu.Item>

    //       {/* <Menu.SubMenu
    //         title={i18next.t("employees")}
    //         icon={<UsergroupDeleteOutlined />}
    //       >
    //         <Menu.Item>
    //           <Link to={`/employees`}  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "All Employees" : " بيانات الموظفين"}
    //           </Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           <Link to={`/addemp`}  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Add Employee" : "اضافة موظف"}
    //           </Link>
    //         </Menu.Item>
    //       </Menu.SubMenu> */}

    //       <Menu.SubMenu
    //         title={lang == "en" ? "Manage List" : "ادارة العقارات"}
    //         icon={<UnorderedListOutlined />}
    //       >
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/adminpending"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Pending " : "عقارات جديدة"}
    //           </Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/adminrejected"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Rejected" : "عقارات مرفوضة"}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/adminlisting"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Published" : "تم النشر"}
    //           </Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/admindraft"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "Drafted/ Inactive" : "عقارات محذوفة"}
    //           </Link>
    //         </Menu.Item>
    //       </Menu.SubMenu>

    //       <Menu.SubMenu
    //         title={i18next.t("agencies")}
    //         icon={<UsergroupDeleteOutlined />}
    //       >
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/agencies"  onClick={() => setVisible(false)}>{i18next.t("manageagency")}</Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/company-agents"  onClick={() => setVisible(false)}>{i18next.t("agencyagents")}</Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/company-properties"  onClick={() => setVisible(false)}>Agency Properties</Link>
    //         </Menu.Item>
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/company-agents-properties"  onClick={() => setVisible(false)}>Agents & Properties</Link>
    //         </Menu.Item>
    //       </Menu.SubMenu>

    //       <Menu.SubMenu
    //         title={lang == "en" ? "Blogs & Pages" : "المقالات والصفحات"}
    //         icon={<UsergroupDeleteOutlined />}
    //       >
    //         <Menu.Item>
    //           {" "}
    //           <Link to="/blogs"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "List Blogs " : " المقالات"}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/newblog"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? " New Blog" : "اضافة موضوع "}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/pages"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? "List Pages" : " كل الصفحات"}
    //           </Link>
    //         </Menu.Item>

    //         <Menu.Item>
    //           {" "}
    //           <Link to="/newpage"  onClick={() => setVisible(false)}>
    //             {lang == "en" ? " New Page" : " انشاء صفحة"}
    //           </Link>
    //         </Menu.Item>
    //       </Menu.SubMenu>

    //       <Menu.Item key="leads" icon={<PhoneOutlined />}>
    //         <Link to={`/leads`}  onClick={() => setVisible(false)}>
    //           {lang == "en" ? "Leads" : "حالات الاتصال"}{" "}
    //         </Link>
    //       </Menu.Item>
    //       <Menu.Item key="leads-report" icon={<FilePdfOutlined />}>
    //         <Link to={`/leads-report`}  onClick={() => setVisible(false)}>
    //          Leads To PDF
    //         </Link>
    //       </Menu.Item>

          
    //     </div>
    //   ) : (
    //     <div>
    //       {role == 2 ? (
    //         <div style={{ marginLeft: "10px", marginRight: "5px" }}>
    //           <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //             {" "}
    //             <Link to={`/dashboard`} onClick={() => setVisible(false)}>
    //               {i18next.t("dashboard")}
    //             </Link>
    //           </Menu.Item>

    //           {/* <Menu.SubMenu
    //             key="list"
    //             title={i18next.t("listing")}
    //             icon={<UnorderedListOutlined />}
    //           > */}
    //             <Menu.Item key="pending">
    //               <Link to={`/pending`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Pending" : "في الإنتظار"}
    //               </Link>
    //             </Menu.Item>

    //             <Menu.Item key="publish">
    //               <Link to={`/publish`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Publish" : " تم النشر"}
    //               </Link>
    //             </Menu.Item>
    //             <Menu.Item key="reject">
    //               <Link to={`/rejected`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Rejected" : " تم الرفض"}{" "}
    //               </Link>
    //             </Menu.Item>
    //             <Menu.Item key="draft">
    //               <Link to={`/draft`} onClick={() => setVisible(false)}>
    //                 {lang == "en" ? "Drafts/ Inactive" : "  غير نشط / مسودة"}
    //               </Link>
    //             </Menu.Item>
    //             <Menu.Item key="quota">
    //               <Link to={`/usage-quota`}  onClick={() => setVisible(false)}>{i18next.t("quotausage")}</Link>
    //             </Menu.Item>
    //           {/* </Menu.SubMenu> */}

    //           <Menu.SubMenu
    //             key="usermanagement"
    //             title={lang == "en" ? "User Management" : "ادارة المستخدمين"}
    //             icon={<UsergroupDeleteOutlined />}
    //           >
    //             <Menu.Item key="agent">
    //               <Link to="/agents" onClick={() => setVisible(false)}>
    //                 {i18next.t("manageuser")}
    //               </Link>
    //             </Menu.Item>

    //             <Menu.Item key="adduser">
    //               <Link to="/addnewuser" onClick={() => setVisible(false)}>
    //                 {i18next.t("adduser")}
    //               </Link>
    //             </Menu.Item>
    //           </Menu.SubMenu>
    //           <Menu.Item key="all-leads" icon={<PhoneOutlined />}>
    //             <Link to={`/all-leads`} onClick={() => setVisible(false)}>
    //               {lang == "en" ? "Leads" : "حالات الاتصال"}{" "}
    //             </Link>
    //           </Menu.Item>

    //           <Menu.Item key="addproperty" style={{ marginTop: "100px" }}>
    //             <Button
    //               size="large"
    //               shape="round"
    //               type="primary"
    //               icon={<PlusOutlined />}
    //             >
    //               <Link
    //                 to="/addproperty"
    //                 style={{ color: "white" }}
    //                 onClick={() => setVisible(false)}
    //               >
    //                 {i18next.t("addproperty")}
    //               </Link>
    //             </Button>
    //           </Menu.Item>
    //         </div>
    //       ) : (
    //         <div>
    //           {role == 3 ? (
    //             <div style={{ marginLeft: "10px", marginRight: "10px" }}>
    //               <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //                 <Link to={`/dashboard`} onClick={() => setVisible(false)}>
    //                   {i18next.t("dashboard")}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.SubMenu
    //                 title={i18next.t("listing")}
    //                 icon={<UnorderedListOutlined />}
    //               >
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/pending-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en" ? "Pending" : "في الإنتظار"}
    //                   </Link>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/publish-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en" ? "Publish" : " تم النشر"}
    //                   </Link>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/rejected-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en" ? "Rejected" : " تم الرفض"}
    //                   </Link>
    //                 </Menu.Item>
    //                 <Menu.Item>
    //                   {" "}
    //                   <Link
    //                     to={`/draft-property`}
    //                     onClick={() => setVisible(false)}
    //                   >
    //                     {lang == "en"
    //                       ? "Drafts/ Inactive"
    //                       : "  غير نشط / مسودة"}
    //                   </Link>
    //                 </Menu.Item>
    //               </Menu.SubMenu>
    //               <Menu.Item key="allleads" icon={<PhoneOutlined />}>
    //                 <Link to={`/allleads`} onClick={() => setVisible(false)}>
    //                   {lang == "en" ? "Leads" : "حالات الاتصال"}{" "}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item key="add-property" style={{ marginTop: "100px" }}>
    //                 <Button
    //                   size="large"
    //                   shape="round"
    //                   type="primary"
    //                   icon={<PlusOutlined />}
    //                 >
    //                   <Link
    //                     to="/add-property"
    //                     onClick={() => setVisible(false)}
    //                     style={{ color: "white" }}
    //                   >
    //                     {i18next.t("addproperty")}
    //                   </Link>
    //                 </Button>
    //               </Menu.Item>
    //             </div>
    //           ) : (
    //             <div style={{ marginLeft: "10px", marginRight: "10px" }}>
    //               <Menu.Item key="dashboard" icon={<DashboardFilled />}>
    //                 <Link to={`/dashboard`}  onClick={() => setVisible(false)}>{i18next.t("dashboard")}</Link>
    //               </Menu.Item>

    //               <Menu.Item>
    //                 {" "}
    //                 <Link to={`/blogs`}  onClick={() => setVisible(false)}>
    //                   {lang == "en" ? "Blogs" : "المقالات"}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item>
    //                 {" "}
    //                 <Link to={`/newblog`}  onClick={() => setVisible(false)}>
    //                   {lang == "en" ? "New Blog" : " مقال جديد "}
    //                 </Link>
    //               </Menu.Item>
    //               {/* <Menu.Item>
    //                 {" "}
    //                 <Link to={`/pages`}>
    //                   {lang == "en" ? "Pages" : "  الصفحات"}
    //                 </Link>
    //               </Menu.Item>
    //               <Menu.Item>
    //                 {" "}
    //                 <Link to={`/newpage`}>
    //                   {lang == "en" ? "New Page" : "صفحة جديدة"}
    //                 </Link>
    //               </Menu.Item> */}
    //             </div>
    //           )}
    //         </div>
    //       )}
    //     </div>
    //   )}
    // </Menu>
  );

  return (
    <>
      {authenticated ? (
        <>
          {going ? (
            <Bye />
          ) : (
            <Layout dir={lang == "en" ? "ltr" : "rtl"} style={{ minHeight: '100vh',backgroundColor: "white" }}>
              <Header
                style={{ width: "100vw", backgroundColor: "white" }}
                className=""
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    {width > 720 ? (
                      <img
                        src="/logo.png"
                        style={{ paddingLeft: "0px", paddingRight: "10px" }}
                        alt="Logo"
                      />
                    ) : (
                      <></>
                    )}

                    {width > 720
                      ? React.createElement(
                          collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                          {
                            className: "trigger",
                            onClick: () => setCollapsed(!collapsed),
                          }
                        )
                      : React.createElement(
                          collapsed ? MenuOutlined : MenuOutlined,
                          {
                            className: "trigger",
                            onClick: () => setVisible(true),
                          }
                        )}
                  </div>
         

                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                  <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                    <Dropdown overlay={menu1}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          {lang == "en" ? (
                            <>
                              <span className="flag-icon flag-icon-gb"> </span>{" "}
                              {width > 720 ? "English" : ""}
                            </>
                          ) : (
                            <>
                              <span className="flag-icon flag-icon-ae"> </span>{" "}
                              {width > 720 ? "العربية" : ""}
                            </>
                          )}
                          {width > 720 ? <DownOutlined /> : null}
                        </Space>
                      </a>
                    </Dropdown>
                  </div>
                  <div style={{paddingLeft:'20px',paddingRight:'20px'}}>
                    <Dropdown overlay={menu}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          <h4>{width > 720 ? user.name : null}</h4>
                          <Avatar
                            size="large"
                            style={{
                              backgroundColor: "#87d068",
                              marginRight: "1rem",
                            }}
                            icon={
                              <img
                                src={
                                  baseurlImg +
                                  "/uploads/profiles/" +
                                  user.profile
                                }
                                style={{
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "10px",
                                }}
                              />
                            }
                          />
                        </Space>
                      </a>
                    </Dropdown>
                    </div>
                  </div>
                </div>

                <Menu
                  theme="light"
                  mode="horizontal"
                  defaultSelectedKeys={["2"]}
                  items={[]}
                />
              </Header>

              <Layout>
                {width > 720 ? (
                  <Sider trigger={null} collapsible collapsed={collapsed}>
                    <NavItems />
                  </Sider>
                ) : (
                  <Drawer
                    style={{
                      padding: "0px !important",
                      marginTop: "200px !important",
                    }}
                    closable={true}
                    onClose={onClose}
                    visible={visible}
                    className="mobile-sidebar"
                    placement={"left"}
                    key={"left"}
                  >
                    <NavItems />
                  </Drawer>
                )}

                <Layout
                  style={{
                    padding: "0 24px 24px",
                  }}
                >
                  <Content
                    className="site-layout-background"
                    style={{
                      padding: 24,
                      margin: 0,
                      minHeight: 280,
                    }}
                  >
                    <PrivateRoute
                      exact
                      path="/dashboard"
                      component={Dashboard}
                    />
                    <PrivateRoute exact path="/profile" component={Profile} />
                    <PrivateRoute
                      exact
                      path="/change-password"
                      component={Changepassword}
                    />
                    {/* admin routes */}
                    <AdminPrivate
                      exact
                      path="/categories"
                      // roles={[1]}
                      component={Categories}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-categories/:id"
                      //roles={[1]}
                      component={EditCategory}
                    />

                    <AdminPrivate
                      exact
                      path="/propertytypes"
                      component={PropertyType}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-propertytype/:id"
                      component={EditPropertyType}
                    />
                    <AdminPrivate exact path="/addemp" component={NewUser} />
                    <AdminPrivate
                      exact
                      path="/employees"
                      component={Employees}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-employee/:id"
                      component={EditEmployee}
                    />
                    <AdminPrivate
                      exact
                      path="/agencies"
                      component={ListAgencies}
                    />
                    <AdminPrivate
                      exact
                      path="/add-agency"
                      component={NewAgency}
                    />
                    <AdminPrivate
                      exact
                      path="/company-agents"
                      component={AgencyAgents}
                    />
                    <AdminPrivate
                      exact
                      path="/company-properties"
                      component={AgencyProperties}
                    />
                    <AdminPrivate
                      exact
                      path="/company-agents-properties"
                      component={AgencyAgentsAndProperties}
                    />
                    <AdminPrivate
                      exact
                      path="/show-agency-details/:id"
                      component={ShowAgencyDetails}
                    />
                    <AdminPrivate exact path="/leads" component={Leads} />

                    <AdminPrivate exact path="/calls/:id" component={Call} />

                    <AdminPrivate exact path="/clicks/:id" component={Click} />

                    <AdminPrivate
                      exact
                      path="/whatsapp/:id"
                      component={Whatsapp}
                    />

                    <AdminPrivate
                      exact
                      path="/leads-report"
                      component={AgencyLeadsReport}
                    />
                    {/* agencies route */}
                    <AgencyPrivate exact path="/agents" component={Agents} />
                    <AgencyPrivate
                      exact
                      path="/addnewuser"
                      component={AddNewUser}
                    />
                    <AgencyPrivate
                      exact
                      path="/edit-agent/:id"
                      component={EditAgent}
                    />

                    <AdminPrivate
                      exact
                      path="/admin-edit-agent/:id"
                      component={AdminEditAgent}
                    />

                    <PrivateRoute
                      exact
                      path="/managelistings"
                      component={Managelisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/usage-quota"
                      component={UsageList}
                    />
                    <AdminPrivate
                      exact
                      path="/edit-agency/:id"
                      component={EditAgency}
                    />
                    <AgencyPrivate
                      path="/show-agency/:id"
                      component={ShowAgency}
                    />
                    <AgencyPrivate
                      exact
                      path="/addProperty"
                      component={AddProperty}
                    />
                    <AgencyPrivate
                      exact
                      path="/editProperty/:id"
                      component={EditProperty}
                    />
                    <AgentPrivate
                      exact
                      path="/add-Property"
                      component={AgentProperty}
                    />
                    <AgentPrivate
                      exact
                      path="/editagentProperty/:id"
                      component={EditAgentProperty}
                    />

                    <PrivateRoute exact path="/blogs" component={Listblogs} />
                    <PrivateRoute exact path="/newblog" component={Newblog} />
                    <PrivateRoute
                      exact
                      path="/edit-blog/:id"
                      component={Editblog}
                    />
                    <AgentPrivate
                      exact
                      path="/agentmanagelistings"
                      component={AgentManagelisting}
                    />
                    <PrivateRoute exact path="/pages" component={Listpages} />
                    <PrivateRoute exact path="/newpage" component={Newpage} />
                    <PrivateRoute
                      exact
                      path="/edit-page/:id"
                      component={Editpage}
                    />
                    <AdminPrivate
                      exact
                      path="/adminlisting"
                      component={AdminManagelist}
                    />
                    <AdminPrivate
                      exact
                      path="/admindraft"
                      component={AdminDraft}
                    />
                    <AdminPrivate
                      exact
                      path="/adminpending"
                      component={Adminpending}
                    />
                    <AdminPrivate
                      exact
                      path="/adminrejected"
                      component={AdminRejected}
                    />
                    <AdminPrivate
                      exact
                      path="/admineditlist/:id"
                      component={AdminEditList}
                    />
                    <SeoPrivate
                      exact
                      path="/seolisting"
                      component={SeoManagelisting}
                    />
                    <SeoPrivate
                      exact
                      path="/seoeditlist/:id"
                      component={SeoEditList}
                    />
                    <AgencyPrivate
                      exact
                      path="/pending"
                      component={Pendinglisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/publish"
                      component={Publishlisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/edit-Prop/:id"
                      component={EditPublishProperty}
                    />
                    <AgencyPrivate
                      exact
                      path="/rejected"
                      component={Rejectedlisting}
                    />
                    <AgencyPrivate
                      exact
                      path="/edit-Property/:id"
                      component={EditRejectedProperty}
                    />
                    <AgencyPrivate
                      exact
                      path="/draft"
                      component={Draftlisting}
                    />

                    <AgencyPrivate
                      exact
                      path="/all-leads"
                      component={AgencyLeads}
                    />

                 

                    <AgentPrivate
                      exact
                      path="/pending-property"
                      component={AgentPendinglisting}
                    />
                    <AgentPrivate
                      exact
                      path="/publish-property"
                      component={AgentPublishlisting}
                    />
                    <AgentPrivate
                      exact
                      path="/editprop/:id"
                      component={EditPublishAgent}
                    />
                    <AgentPrivate
                      exact
                      path="/rejected-property"
                      component={AgentRejectedlisting}
                    />
                    <AgentPrivate
                      exact
                      path="/edit-agentProperty/:id"
                      component={EditRejectedAgentProperty}
                    />
                    <AgentPrivate
                      exact
                      path="/draft-property"
                      component={AgentDraftlisting}
                    />
                    <AgentPrivate
                      exact
                      path="/allleads"
                      component={AgentLeads}
                    />

                    {/* <PrivateRoute exact path="*">
                <Notfound />
              </PrivateRoute> */}
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          )}
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default SidebarComponent;
