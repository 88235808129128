import React, { Component } from "react";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import cookie from "js-cookie";

import i18next from "i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import "./UsageList.css";

import 'antd/dist/antd.css';
import {Table,Breadcrumb,Spin} from 'antd'
import { ArrowRightOutlined,ArrowLeftOutlined} from '@ant-design/icons';

import {itemRender,onShowSizeChange} from "../../paginationfunction"
import "../../antdstyle.css"

import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';


import API from "../../../utils/API";

const lang = localStorage.getItem("lang") || "en";

class UsageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      category_en: "",
      category_ar: "",
      isLoading: false,
      startDate: new Date(),
      agents: [],
      filterLocation: [],
      loc_tap: "nav-link active",
      day_tap: "nav-link",
      selected_item: 1,
      selectedLocations: [],
      selectedUsers: [],
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    this.fetchData();
  }

  fetchData = () => {
    this.setState({ isLoading: true });
    const token = cookie.get("token");
    API
      .get("/users")
      .then((response) => {
        this.setState({
          agents:
            lang == "en" ? response.data.agents_en : response.data.agents_ar,
        });
      })

      .catch((e) => console.log("error"));

    API
      .get("/filterLocation")
      .then((response) => {
        this.setState({
          filterLocation:
            lang == "en"
              ? response.data.locations_en
              : response.data.locations_ar,
        });
      })

      .catch((e) => console.log("error"));

    API
      .get( "/agencypackegedetailswithusage")
      .then((response) => {

        this.setState({ isLoading: false, locations: response.data.locations });
     
      })
      .catch((e) => console.log("error"));
  };

  fetchLocationsbyuser = (newValue) => {
    const data = { users: newValue, locations: this.state.selectedLocations };
    const token = cookie.get("token");
    this.setState({ isLoading: true });
    API
      .post("/filterusagepackage", data)
      .then((response) => {
        console.log("server response", response.data);
        this.setState({ locations: response.data.locations, isLoading: false });
      })

      .catch((e) => (e) => console.log("error"));
  };
  // select by location name
  fetchLocationsbylocation = (newValue) => {
    const data = { users: this.state.selectedUsers, locations: newValue };
    const token = cookie.get("token");
    this.setState({ isLoading: true });
    API
      .post("/filterusagepackage", data)
      .then((response) => {
        console.log("server response", response.data);
        this.setState({ locations: response.data.locations, isLoading: false });
      })

      .catch((e) => (e) => console.log("error"));
  };

  selectLocation = (e) => {
    this.setState({
      loc_tap: "nav-link active",
      day_tap: "nav-link",
      selected_item: 1,
    });
  };

  selectDay = (e) => {
    this.setState({
      loc_tap: "nav-link",
      day_tap: "nav-link active",
      selected_item: 2,
    });
  };

  render() {

    const columns = [  
            
      {
          title: i18next.t("location"),
          render:(text,record)=>(
              <>
            {lang == "en" ? record.area_en : record.area_ar}
            </>
          )
        },

       // 


        {
            title: i18next.t("forrent"),
            render:(text,record)=>(
                <>   {record["rent"].length > 0
                ? record["rent"].map(
                    (rent) => rent.rent_count
                  )
                : 0}
              
              </>
            )
          
    
        },
          {
              title: i18next.t("forbuy"),
              render:(text,record)=>(
                  <>
               {record["buy"].length > 0
                                        ? record["buy"].map((buy) => buy.buy_count)
                                        : 0}
                </>
              )
    
          },
          {
            title: i18next.t("basic"),
            render:(text,record)=>(
              <>
             {record["basic"].length > 0
              ? record["basic"].map(
               (basic) => basic.basic_count
                )
               : 0}
            </>
          )
          },
          {
            title: i18next.t("featured"),
            render:(text,record)=>(
                <>
                  {record["featured"].length > 0
                                        ? record["featured"].map(
                                            (featured) =>
                                              featured.featured_count
                                          )
                                        : 0}
                </>
            )
           // dataIndex: 'beds',
          //  sorter: (a, b) => a.beds.length - b.beds.length,
          },
          {
            title: i18next.t("premium"),
            render:(text,record)=>(
              <>
                 {record["premium"].length > 0
                  ? record["premium"].map(
                 (premium) => premium.premium_count
               )
              : 0}
              </>
          )
          },
        {
          title: i18next.t("total"),
          render: (text, record) => (
            record.property_count
        
            ),
        },
    
 
    ]

    return (
      <div>
        <div className="container-fluid">
          <div className=" page-titles">
          <Breadcrumb
          style={{
            margin: '16px 0',
          }}
        >
          <Breadcrumb.Item>
          {lang=="en"?<ArrowRightOutlined />:<ArrowLeftOutlined />}
          </Breadcrumb.Item>
          <Breadcrumb.Item>{i18next.t("quotausage")}</Breadcrumb.Item>
          
        </Breadcrumb>

         
            <div className="row" style={{ marginTop: "20px" }}>
              <div className="col-md-3">
                <label>{i18next.t("users")}</label>
                <Autocomplete
                  multiple
                  options={this.state.agents}
                  onChange={(event, newValue) => {
                    this.setState({ selectedUsers: newValue });
                    this.fetchLocationsbyuser(newValue);
                  }}
                  getOptionLabel={(option) => option.name}
                  // defaultValue={[top100Films[13],]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label=""
                      placeholder=""
                    />
                  )}
                />
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-3">
                <label>{i18next.t("location")}</label>
                <Autocomplete
                  multiple
                  options={this.state.filterLocation}
                  defaultValue={this.state.selectedLocations}
                  onChange={(event, newValue) => {
                    this.setState({ selectedLocations: newValue });
                    this.fetchLocationsbylocation(newValue);
                    //  const agentData= {selectedLanguage: this.selectedLanguage, selectedNationality: this.state.selectedNationality, selectedLocation: newValue, selectedArea: this.state.selectedArea}
                    // this.fetchLocations(newValue)
                    //console.log("selected Loca", this.state.locationValue)
                  }}
                  getOptionLabel={(option) => option.location}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label=""
                      placeholder=""
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>

                                   <Table className="table-striped"
                                    loading={this.state.isLoading}
                                    pagination= { {total : this.state.locations.length,
                                        showTotal : (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                                        showSizeChanger : true,onShowSizeChange: onShowSizeChange ,itemRender : itemRender } }
                                    style = {{overflowX : 'auto'}}
                                    columns={columns}                 
                                    // bordered
                                    dataSource={this.state.locations}
                                    rowKey={record => record}
                                    />

     
        </div>
      </div>
    );
  }
}

export default UsageList;
