import React, { Component } from "react";
import jQuery from "jquery";
import "./AddProperty.css";
import i18next from "i18next";
import Swal from "sweetalert2";

import "./cssfile.css";
import { Badge, Breadcrumb } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import ClipLoader from "react-spinners/ClipLoader";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Helmet from "react-helmet";
import Select from "react-select";
import "./styles.css";

import "../../Test.css";
import $ from "jquery";
import API from "../../utils/API";
let map;
let service;
let infowindow;

let lang = localStorage.getItem("lang") || "en";
let imagesArray = [];
const config = {
  readonly: false,
  uploader: {
    url: "https://services.findproperties.ae/api/uploader",
  },
  toolbar: false,
  insert_clear_html: true,
  askBeforePasteFromWord: true,
  askBeforePasteHTML: false,
};

class AgentProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alllocations: [],
      emirates: [],
      areas: [],
      subareas: [],
      categories: [],
      propertytypes: [],
      agents: [],
      agent: {},
      propertyType_en: "",
      propertyType_ar: "",
      categoryId: "0",
      propertytype_id: "0",
      propertyTypeDisabld: "disabled",
      rentOrSale: "",
      bathroomfeature: "row d-none",
      furnishstatusfields: "mb-3 d-none",
      rentfileds: "row d-none",
      occupancyfiled: "row d-none",
      readyoffPlanfield: "row d-none",
      landfeatures: "row d-none",
      location_name_en: "",
      location_name_ar: "",
      address: "",
      address_ar: "",
      country: "",
      country_ar: "",
      emirate: "",
      emirate_ar: "",
      area: "",
      area_ar: "",
      subarea_en: "",
      subarea_ar: "",
      streetorbuild: "",
      streetorbuild_ar: "",
      firnished: 0,
      checked: false,
      gym: false,
      swimmingpool: false,
      medical: false,
      sauna_steem: false,
      disability_access: false,
      built_in_wardobes: false,
      barbaque_area: false,
      cafeteria: false,
      kitchen: false,
      central_ac: false,
      garden: false,
      furnished: false,
      disposal: false,
      kidsplay: false,
      parking: false,
      balcony: false,
      atm_acxeess: false,
      water_view: false,
      landmark_view: false,
      day_care: false,
      security_guard: false,
      cctv: false,
      internet: false,
      satelite_cable_tv: false,
      title_en: "",
      title_ar: "",
      description_en: "",
      description_ar: "",
      square_area: 0,
      price: 0,
      bedroom: -1,
      bathroom: 0,
      rent_frequency: 0,
      completion_status: 0,
      ownership_status: 0,
      min_contract_period: 0,
      vacating_period: 0,
      maintainance_fee: 0,
      paid_by: 0,
      permit_number: 0,
      videoRows: [],
      allfiles: [],
      video_source: 0,
      video_title: "",
      video_link: "",
      purpose: 1,
      image: "",
      featuredimage: null,
      imageShow: null,
      agentLoading: false,
      lat: 25.1972018,
      lng: 55.2721877,

      selectplace: "",
      availablepackage: 0,
      isValidAddress: false,
      showAddress: "",
      isMax: false,
      selectedEmirate: 0,
      selectedArea: 0,
      sele: 0,
      selectedSubarea: 0,
      selectedAreaOption: {},
      selectedSubareaOption: {},
      mapvalue: "",
      selectPlace: "",
      offplanready:0
    };
    this.mapContainer = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleFeaturedImage = this.handleFeaturedImage.bind(this);
  }

  componentDidMount() {
    // var loadScript = function (src) {
    //     var tag = document.createElement('script');
    //     tag.async = false;
    //     tag.src = src;
    //     document.getElementsByTagName('body')[0].appendChild(tag);
    // }
    // loadScript('./vendor/global/global.min.js')
    // loadScript('./js/deznav-init.js')
    // loadScript('./js/custom.min.js')

    this.fetchData();
    jQuery(document).ready(function () {
      jQuery(".form-wizard-next-btn").click(function () {
        var parentFieldset = jQuery(this).parents(".wizard-fieldset");
        var currentActiveStep = jQuery(this)
          .parents(".form-wizard")
          .find(".form-wizard-steps .active");
        var next = jQuery(this);
        var nextWizardStep = true;

        parentFieldset.find(".wizard-required").each(function () {
          var thisValue = jQuery(this).val();
          var category_id = jQuery("#category_id").val();
          var type_id = jQuery("#type_id").val();
          var purpose = jQuery("#purpose").val();
          var agent_id = jQuery("#agent_id").val();
          var title_en = jQuery("#title_en").val();
          var description_en = jQuery("#description_en").val();
          var area = jQuery("#area").val();
          var price = jQuery("#price").val();
          var rentfrequency = jQuery("#rentfrequency").val();
          var mincontract = jQuery("#mincontract").val();
          var vacatingperiod = jQuery("#vacatingperiod").val();
          var mantianancefee = jQuery("#mantianancefee").val();
          var paidby = jQuery("#paidby").val();
          var permitnumber = jQuery("#permitnumber").val();

          var location_selected = jQuery("#location_selected").val();
          if (location_selected < 1) {
            jQuery("#location_selected").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#location_selected").css("border", "2px solid #eee");
          }

          if (permitnumber == "") {
            jQuery("#permitnumber").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#permitnumber").css("border", "2px solid #eee");
          }
          if (category_id < 1) {
            jQuery("#category_id").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#category_id").css("border", "2px solid #eee");
          }
          if (type_id < 1) {
            jQuery("#type_id").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#type_id").css("border", "2px solid #eee");
          }
          if (purpose < 1) {
            nextWizardStep = false;
            jQuery("#purpose").css("border", "2px solid red");
          } else {
            jQuery("#purpose").css("border", "2px solid #eee");
          }
          if (agent_id < 1) {
            jQuery("#agent_id").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#agent_id").css("border", "2px solid #eee");
          }
          if (purpose == 1) {
            if (rentfrequency < 1) {
              jQuery("#rentfrequency").css("border", "2px solid red");
              nextWizardStep = false;
            } else {
              jQuery("#rentfrequency").css("border", "2px solid #eee");
            }
            if (mincontract == "") {
              jQuery("#mincontract").css("border", "2px solid red");
              nextWizardStep = false;
            } else {
              jQuery("#mincontract").css("border", "2px solid #eee");
            }
            if (vacatingperiod == "") {
              jQuery("#vacatingperiod").css("border", "2px solid red");
              nextWizardStep = false;
            } else {
              jQuery("#vacatingperiod").css("border", "2px solid #eee");
            }
            if (mantianancefee == "") {
              jQuery("#mantianancefee").css("border", "2px solid red");
              nextWizardStep = false;
            } else {
              jQuery("#mantianancefee").css("border", "2px solid #eee");
            }
            if (paidby < 1) {
              jQuery("#paidby").css("border", "2px solid red");
              nextWizardStep = false;
            } else {
              jQuery("#paidby").css("border", "2px solid #eee");
            }
          } else {
            jQuery("#rentfrequency").css("border", "2px solid #eee");
          }
          if (title_en == "") {
            jQuery("#title_en").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#title_en").css("border", "2px solid #eee");
          }
          if (description_en == "") {
            jQuery("#description_en").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#description_en").css("border", "2px solid #eee");
          }
          if (area == "") {
            jQuery("#area").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#area").css("border", "2px solid #eee");
          }
          if (price == "") {
            jQuery("#price").css("border", "2px solid red");
            nextWizardStep = false;
          } else {
            jQuery("#price").css("border", "2px solid #eee");
          }
          if (thisValue == "") {
            jQuery(this).siblings(".wizard-form-error").slideDown();
            nextWizardStep = false;
          } else {
            jQuery(this).siblings(".wizard-form-error").slideUp();
          }
        });
        if (nextWizardStep) {
          next.parents(".wizard-fieldset").removeClass("show", "400");
          currentActiveStep
            .removeClass("active")
            .addClass("activated")
            .next()
            .addClass("active", "400");
          next
            .parents(".wizard-fieldset")
            .next(".wizard-fieldset")
            .addClass("show", "400");
          jQuery(document)
            .find(".wizard-fieldset")
            .each(function () {
              if (jQuery(this).hasClass("show")) {
                var formAtrr = jQuery(this).attr("data-tab-content");
                jQuery(document)
                  .find(".form-wizard-steps .form-wizard-step-item")
                  .each(function () {
                    if (jQuery(this).attr("data-attr") == formAtrr) {
                      jQuery(this).addClass("active");
                      var innerWidth = jQuery(this).innerWidth();
                      var position = jQuery(this).position();
                      jQuery(document)
                        .find(".form-wizard-step-move")
                        .css({ left: position.left, width: innerWidth });
                    } else {
                      jQuery(this).removeClass("active");
                    }
                  });
              }
            });
        }
      });
      jQuery(".form-wizard-previous-btn").click(function () {
        var counter = parseInt(jQuery(".wizard-counter").text());
        var prev = jQuery(this);
        var currentActiveStep = jQuery(this)
          .parents(".form-wizard")
          .find(".form-wizard-steps .active");
        prev.parents(".wizard-fieldset").removeClass("show", "400");
        prev
          .parents(".wizard-fieldset")
          .prev(".wizard-fieldset")
          .addClass("show", "400");
        currentActiveStep
          .removeClass("active")
          .prev()
          .removeClass("activated")
          .addClass("active", "400");
        jQuery(document)
          .find(".wizard-fieldset")
          .each(function () {
            if (jQuery(this).hasClass("show")) {
              var formAtrr = jQuery(this).attr("data-tab-content");
              jQuery(document)
                .find(".form-wizard-steps .form-wizard-step-item")
                .each(function () {
                  if (jQuery(this).attr("data-attr") == formAtrr) {
                    jQuery(this).addClass("active");
                    var innerWidth = jQuery(this).innerWidth();
                    var position = jQuery(this).position();
                    jQuery(document)
                      .find(".form-wizard-step-move")
                      .css({ left: position.left, width: innerWidth });
                  } else {
                    jQuery(this).removeClass("active");
                  }
                });
            }
          });
      });
      jQuery(document).on(
        "click",
        ".form-wizard .form-wizard-submit",
        function () {
          var parentFieldset = jQuery(this).parents(".wizard-fieldset");
          var currentActiveStep = jQuery(this)
            .parents(".form-wizard")
            .find(".form-wizard-steps .active");
          parentFieldset.find(".wizard-required").each(function () {
            var thisValue = jQuery(this).val();

            if (thisValue == "") {
              jQuery(this).siblings(".wizard-form-error").slideDown();
            } else {
              jQuery(this).siblings(".wizard-form-error").slideUp();
            }
          });
        }
      );
      jQuery(".form-control")
        .on("focus", function () {
          var tmpThis = jQuery(this).val();
          if (tmpThis == "") {
            jQuery(this).parent().addClass("focus-input");
          } else if (tmpThis != "") {
            jQuery(this).parent().addClass("focus-input");
          }
        })
        .on("blur", function () {
          var tmpThis = jQuery(this).val();
          if (tmpThis == "") {
            jQuery(this).parent().removeClass("focus-input");
            jQuery(this).siblings(".wizard-form-error").slideDown("3000");
          } else if (tmpThis != "") {
            jQuery(this).parent().addClass("focus-input");
            jQuery(this).siblings(".wizard-form-error").slideUp("3000");
          }
        });
      jQuery("#title_en").keypress(function (event) {
        var ew = event.which;
        if (ew == 32) return true;
        if (48 <= ew && ew <= 57) return true;
        if (65 <= ew && ew <= 90) return true;
        if (97 <= ew && ew <= 122) return true;
        return false;
      });

      jQuery("#description_en").keypress(function (event) {
        var ew = event.which;
        if (ew == 32) return true;
        if (48 <= ew && ew <= 57) return true;
        if (65 <= ew && ew <= 90) return true;
        if (97 <= ew && ew <= 122) return true;
        return false;
      });
    });
  }

  rentOnChange_Handle = (e) => {
    e.preventDefault();
    e.target.value == 1
      ? this.setState({ rentfileds: "row" })
      : this.setState({ rentfileds: "d-none" });
    this.setState({ purpose: e.target.value });
  };
  propertyType_enHandle = (e) => {
    this.setState({ propertytype_id: e.target.value });

    let id = e.target.value;
    API.get("/propertytypeById/" + id)
      .then((response) => {
        this.setState({
          occupancyfiled:
            response.data.occupancy == 1 ? "mb-3 d-block" : "mb-3 d-none",
          furnishstatusfields:
            response.data.furnishedornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
          readyoffPlanfield:
            response.data.readyoffplan == 1 ? "mb-3 d-block" : "d-none",
          bathroomfeature:
            response.data.bedandbath == 1 ? "row d-block" : "d-none",
          categoryNameEn: response.data.name_en,
          categoryNameAr: response.data.name_ar,
        });
      })
      .catch((err) => console.log("error"));
  };
  category_enHandle = (e) => {
    e.preventDefault();

    this.setState({ categoryId: e.target.value });
    this.setState({ propertyTypeDisabld: "" });
    if (e.target.value == 23) {
      this.setState({ furnishedStatus: "mb-3 d-block" });
    }
    API.get("/propertytypeByCatId/" + e.target.value)
      .then((response) => {
        this.setState({
          isLoading: false,
          propertytypes: response.data.propertytypes,
        });
      })
      .catch((err) => console.log("error"));
  };

  handleLocation = (opt) => {
    if (opt.value > 0) {
      $("#location_selected").val(opt.label);
      this.setState({ selectPlace: opt });
      this.initMap();
    }
  };

  handleOnFurnished = (e) => {
    this.setState({ firnished: 1 });
  };
  handleOnUnFurnished = (e) => {
    this.setState({ firnished: 0 });
  };
  handleOffplan= (e) => {
    this.setState({ offplanready: 0 });
  };

  handleOffready= (e) => {
    this.setState({ offplanready: 1 });
  };

  handleGym = (e) => {
    this.setState({ gym: !this.state.gym });
  };
  handleSwimmingbool = (e) => {
    this.setState({ swimmingpool: !this.state.swimmingpool });
  };
  handleMedical = (e) => {
    this.setState({ medical: !this.state.medical });
  };
  handleSaunasteem = (e) => {
    this.setState({ sauna_steem: !this.state.sauna_steem });
  };
  handleDisabilityaccess = (e) => {
    this.setState({ disability_access: !this.state.disability_access });
  };
  handleBuiltinwardobes = (e) => {
    this.setState({ built_in_wardobes: !this.state.built_in_wardobes });
  };
  handleBarbaquearea = (e) => {
    this.setState({ barbaque_area: !this.state.barbaque_area });
  };
  handleCafeteria = (e) => {
    this.setState({ cafeteria: !this.state.cafeteria });
  };

  handleKitchen = (e) => {
    this.setState({ kitchen: !this.state.kitchen });
  };
  handleCentralac = (e) => {
    this.setState({ central_ac: !this.state.central_ac });
  };

  handleGarden = (e) => {
    this.setState({ garden: !this.state.garden });
  };
  handleFurnished = (e) => {
    this.setState({ furnished: !this.state.furnished });
  };
  handleDisposal = (e) => {
    this.setState({ disposal: !this.state.disposal });
  };
  handleKidsplay = (e) => {
    this.setState({ kidsplay: !this.state.kidsplay });
  };
  handleParking = (e) => {
    this.setState({ parking: !this.state.parking });
  };
  handleBalcony = (e) => {
    this.setState({ balcony: !this.state.balcony });
  };

  handleAtmacxeess = (e) => {
    this.setState({ atm_acxeess: !this.state.atm_acxeess });
  };
  handleWaterview = (e) => {
    this.setState({ water_view: !this.state.water_view });
  };
  handleLandmarkview = (e) => {
    this.setState({ landmark_view: !this.state.landmark_view });
  };
  handleDaycare = (e) => {
    this.setState({ day_care: !this.state.day_care });
  };

  handleSecurityguard = (e) => {
    this.setState({ security_guard: !this.state.security_guard });
  };
  handleCctv = (e) => {
    this.setState({ cctv: !this.state.cctv });
  };
  handleInternet = (e) => {
    this.setState({ internet: !this.state.internet });
  };
  handleSatelitecabletv = (e) => {
    this.setState({ satelite_cable_tv: !this.state.satelite_cable_tv });
  };
  handleTitleEn = (e) => {
    e.preventDefault();
    this.setState({ title_en: e.target.value });
  };
  handleTitleAr = (e) => {
    this.setState({ title_ar: e.target.value });
  };
  handleDescriptionEn = (e) => {
    this.setState({ description_en: e.target.value });
  };
  handleDescriptionAr = (e) => {
    this.setState({ description_ar: e.target.value });
  };
  handleSquarearea = (e) => {
    this.setState({ square_area: e.target.value });
  };
  handlePrice = (e) => {
    this.setState({ price: e.target.value });
  };
  handleBedroom = (e) => {
    this.setState({ bedroom: e.target.value });
  };
  handleBathroom = (e) => {
    this.setState({ bathroom: e.target.value });
  };
  handleRentFrequency = (e) => {
    this.setState({ rent_frequency: e.target.value });
  };

  handleMinimumcontractperiod = (e) => {
    this.setState({ min_contract_period: e.target.value });
  };
  handleVactingPeriod = (e) => {
    this.setState({ vacating_period: e.target.value });
  };
  handleMaintainaceFee = (e) => {
    this.setState({ maintainance_fee: e.target.value });
  };
  handlePaidby = (e) => {
    this.setState({ paid_by: e.target.value });
  };
  handlePermitNumber = (e) => {
    this.setState({ permit_number: e.target.value });
  };
  handleVideo_source = (e) => {
    e.preventDefault();
    this.setState({ video_source: e.target.value });
  };
  handleVideo_link = (e) => {
    e.preventDefault();
    this.setState({ video_link: e.target.value });
  };
  handleVideo_title = (e) => {
    e.preventDefault();
    this.setState({ video_title: e.target.value });
  };

  fetchData = () => {
    this.setState({ isLoading: true });

    //full location
    API.get("/alllocations")
      .then((response) => {
        this.setState({
          alllocations:
            lang == "en" ? response.data.locations : response.data.locations_ar,
        });
      })
      .catch((err) => console.log("error"));

    // emirates
    API.get("/emirates")
      .then((response) => {
        this.setState({ emirates: response.data.emirates, mapvalue: "Dubai" });

        $("#placename").val("Dubai");

        this.initMap();
      })
      .catch((err) => console.log("error"));

    API.get("/categories")
      .then((response) => {
        this.setState({
          isLoading: false,
          categories: response.data.categories,
        });
      })
      .catch((err) => console.log("error"));

    API.get("/propertytypes")
      .then((response) => {
        this.setState({ propertytypes: response.data.propertytypes });
        this.setState({
          occupancyfiled:
            response.data.occupancy == 1 ? "mb-3 d-block" : "mb-3 d-none",
          furnishstatusfields:
            response.data.furnishedornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
          landfields:
            response.data.landornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
          comandresfields:
            response.data.comandresornot == 1 ? "mb-3 d-block" : "mb-3 d-none",
          readyoffPlanfield:
            response.data.readyoffplan == 1 ? "mb-3 d-block" : "d-none",
          landfeatures:
            response.data.readyoffplan == 1 ? "row d-block" : "d-none",
          bathroomfeature:
            response.data.bedandbath == 1 ? "row d-block" : "d-none",
          categoryNameEn: response.data.name_en,
          categoryNameAr: response.data.name_ar,
        });
      })
      .catch((err) => console.log("error"));
    API.get("/agentavailablepackage")
      .then((response) => {
        this.setState({ availablepackage: response.data.remainpackage });
      })
      .catch((err) => console.log("error"));
  };

  saveData = (e) => {
    e.preventDefault();

    if (this.state.featuredimage == null || this.state.image.length == 0) {
      lang == "en"
        ? toast.error("Please upload property images ")
        : toast.error("الرجاء تحميل صور العقار");
      return;
    }
    if (this.state.availablepackage <= 0) {
      toast.error("No pakage available ");

      return;
    }
    const data = {
      propertytypes_id: this.state.propertytype_id,
      lat: $("#lat").val(),
      lng: $("#lng").val(),
      firnished: this.state.firnished,
      purpose: this.state.purpose,
      offplanready: this.state.offplanready,
      //Amenties
      gym: this.state.gym == true ? 1 : 0,
      swimmingpool: this.state.swimmingpool == true ? 1 : 0,
      medical: this.state.medical == true ? 1 : 0,
      sauna_steem: this.state.sauna_steem == true ? 1 : 0,
      disability_access: this.state.disability_access == true ? 1 : 0,
      built_in_wardobes: this.state.built_in_wardobes == true ? 1 : 0,
      barbaque_area: this.state.barbaque_area == true ? 1 : 0,
      cafeteria: this.state.cafeteria == true ? 1 : 0,
      kitchen: this.state.kitchen == true ? 1 : 0,
      central_ac: this.state.central_ac == true ? 1 : 0,
      garden: this.state.garden == true ? 1 : 0,
      furnished: this.state.furnished == true ? 1 : 0,
      disposal: this.state.disposal == true ? 1 : 0,
      kidsplay: this.state.kidsplay == true ? 1 : 0,
      parking: this.state.parking == true ? 1 : 0,
      balcony: this.state.balcony == true ? 1 : 0,
      atm_acxeess: this.state.atm_acxeess == true ? 1 : 0,
      water_view: this.state.water_view == true ? 1 : 0,
      landmark_view: this.state.landmark_view == true ? 1 : 0,
      day_care: this.state.day_care == true ? 1 : 0,
      security_guard: this.state.security_guard == true ? 1 : 0,
      cctv: this.state.cctv == true ? 1 : 0,
      internet: this.state.internet == true ? 1 : 0,
      satelite_cable_tv: this.state.satelite_cable_tv == true ? 1 : 0,
      // property details
      title_en: this.state.title_en,
      title_ar: this.state.title_ar,
      description_en: this.state.description_en,
      description_ar: this.state.description_ar,
      square_area: this.state.square_area,
      price: this.state.price,
      bedroom: this.state.bedroom,
      bathroom: this.state.bathroom,
      rent_frequency: this.state.rent_frequency,
      min_contract_period: this.state.min_contract_period,
      completion_status: this.state.completion_status,
      ownership_status: this.state.ownership_status,
      vacating_period: this.state.vacating_period,
      maintainance_fee: this.state.maintainance_fee,
      paid_by: this.state.paid_by,
      permit_number: this.state.permit_number,
      video_source: this.state.video_source,
      video_link: this.state.video_link,
      video_title: this.state.video_title,
      featuredimage: this.state.featuredimage,
      referencenumber: this.state.referencenumber,
      selectPlace: this.state.selectPlace,
    };

    this.setState({ isLoading: true });
    API.post("/agentproperties", data)
      .then((response) => {
        
        if (response.data > 0) {
          const formData = new FormData();
          formData.append("property_id", response.data);
          for (let i = 0; i < this.state.image.length; i++) {
            formData.append("images[]", this.state.image[i]);
          }
          API.post("/agentpropertiesuploads", formData)
            .then((response) => {
              imagesArray = []
              this.setState({ isLoading: false });
              Swal.fire({
                title: "Done!",
                text: "Successfully added.",
                icon: "success",
                timer: 1500,
                button: true,
              });
              this.props.history.push("/pending-property");
            })
            .catch((e) => console.log("error from "));
        }
      })
      .catch((e) => console.log("error"));
  };
  handleFeaturedImage = (e) => {
    this.setState({ isMax: false });
    var maxfilesize = 3145728;
    var filesize = e.target.files[0].size;
    if (filesize > maxfilesize) {
      this.setState({ isMax: true });
      return;
    }
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;
    this.createImage(files[0]);
    this.setState({ imageShow: URL.createObjectURL(e.target.files[0]) });
  };

  createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        featuredimage: e.target.result,
      });
    };
    reader.readAsDataURL(file);
  }
  handleChange = (e) => {
    var maxfilesize = 1024 * 1024;
    
    let isValid = "";
    for (let i = 0; i < e.target.files.length; i++) {
      var filesize = e.target.files[i].size;
      if (filesize > maxfilesize) {
        lang == "en"
          ? toast.error(
              "Please upload an image smaller than 3MB " +
                e.target.files[i].name
            )
          : toast.error(
              "الرجاء تحميل صور اقل من 3 ميغا" + e.target.files[i].name
            );

        return;
      }

      imagesArray.push(e.target.files[i]);
    }
    this.setState({
      image: imagesArray,
    });
  
  };

  removeFile = (myfile, e) => {
    e.preventDefault();
    let index = this.state.image.indexOf(myfile);
    var mydata = this.state.image;
    var loopData = [];
    var i;
    for (i = 0; i < mydata.length; i++) {
    
      if (i == index) {
        mydata.splice(index, 1);
      }
    }
    this.setState({ image: mydata });
  };
  handleCompletionStatus = (e) => {
    e.preventDefault();

    this.setState({ completion_status: e.target.value });
  };

  handleOwnerStatus = (e) => {
    e.preventDefault();

    this.setState({ ownership_status: e.target.value });
  };

  buttonRender = () => {
    if (this.state.isLoading) {
      return (
        <a href="javascript:;" className="form-wizard-submit rounded">
          <ClipLoader color={"white"} loading={true} size={15} /> &nbsp;&nbsp;
          {lang == "en" ? "Proccessing" : "جاري معالجة البيانات"}
        </a>
      );
    }
    return (
      <button
        className="form-wizard-submit rounded"
        onClick={this.saveData}
        disabled={this.state.isLoading ? true : false}
      >
        {lang == "en" ? "Submit" : "حفظ"}
      </button>
    );
  };
  renderMap = () => {
    window.initMap = this.initMap;
  };
  initMap = () => {
    let service;
    let infowindow;
    const request = {
      query: $("#location_selected").val(),
      fields: ["name", "geometry"],
    };

    infowindow = new window.google.maps.InfoWindow();
    map = new window.google.maps.Map(document.getElementById("google-map"), {
      center: new window.google.maps.LatLng(this.state.lat, this.state.lng),
      zoom: 15,
      // mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: false,
      draggable: true,
      //    gestureHandling: "cooperative",
      // styles: MAP_STYLES
    });

    service = new window.google.maps.places.PlacesService(map);
    service.findPlaceFromQuery(request, (results, status) => {
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        results
      ) {
        for (let i = 0; i < results.length; i++) {
          this.createMarker(results[0]);
        }

        map.setCenter(results[0].geometry.location);

        $("#lat").val(results[0].geometry.location.lat());
        $("#lng").val(results[0].geometry.location.lng());
      }
    });
  };
  createMarker(place) {
    if (!place.geometry || !place.geometry.location) return;

    const marker = new window.google.maps.Marker({
      map,
      position: place.geometry.location,
      draggable: true,
    });

    window.google.maps.event.addListener(marker, "dragend", function (event) {
      $("#lat").val(this.getPosition().lat());
      $("#lng").val(this.getPosition().lng());
    });

    map.addListener("click", (e) => {
      $("#lat").val(e.latLng.lat());
      $("#lng").val(e.latLng.lng());
      map.setCenter(marker.setPosition(e.latLng));
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>
              {lang == "en" ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {lang == "en" ? "Add Property" : "اضافة عقار"}
            </Breadcrumb.Item>
          </Breadcrumb>

          <Breadcrumb
            style={{
              margin: "0 120px",
            }}
          >
            <Breadcrumb.Item></Breadcrumb.Item>
            <Breadcrumb.Item>
              <Badge.Ribbon
                text={
                  lang == "en"
                    ? "Availble Package     " + this.state.availablepackage
                    : "رصيد متوفر   " + this.state.availablepackage
                }
                color="cyan"
              ></Badge.Ribbon>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <ToastContainer />
        <div className="row">
          <div className="card">
            <div className="card-body">
              <section className="wizard-section">
                <div className="row ">
                  <div className="">
                    <div className="form-wizard">
                      <form action method="post" role="form">
                        <div className="form-wizard-header">
                          <ul className="list-unstyled form-wizard-steps clearfix">
                            <li className="active">
                              <span>1</span>
                            </li>
                            <li>
                              <span>2</span>
                            </li>
                            <li>
                              <span>3</span>
                            </li>
                          </ul>
                        </div>
                        <fieldset className="wizard-fieldset show">
                          <h5>
                            {lang == "en"
                              ? "Property Information"
                              : "معلومات العقار"}{" "}
                          </h5>
                          <div className="form-group">
                            <select
                              onChange={this.propertyType_enHandle}
                              className="form-select wizard-required"
                              style={{ height: "2.75rem" }}
                              aria-label="form-select"
                              id="type_id"
                            >
                              <option value="0">
                                {i18next.t("propertytype")}
                              </option>
                              {this.state.propertytypes.map((ppty) => (
                                <option value={ppty.id}>
                                  {lang == "en"
                                    ? ppty.typeName_en
                                    : ppty.typeName_ar}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group">
                            <select
                              onChange={this.rentOnChange_Handle}
                              className="form-select wizard-required"
                              style={{ height: "2.75rem" }}
                              aria-label="form-select"
                              id="purpose"
                            >
                              <option value="0">{i18next.t("purpose")}</option>
                              <option value="1">
                                {lang == "en" ? "For Rent" : "للايجار"}
                              </option>
                              <option value="2">
                                {lang == "en" ? "For Sale" : "للبيع"}
                              </option>
                            </select>
                          </div>
                          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                          
                          <div   className={this.state.furnishstatusfields}>
                            <div className="wizard-form-radio">
                              <input
                                name="radio-name"
                                id="radio1"
                                type="radio"
                                defaultChecked={
                                  this.state.firnished == 1 ? true : false
                                }
                                onClick={this.handleOnFurnished}
                              />
                              <label htmlFor="radio1">
                                {i18next.t("furnished")}
                              </label>
                            </div>
                            &nbsp; &nbsp;
                            <div className="wizard-form-radio">
                              <input
                                name="radio-name"
                                id="radio2"
                                type="radio"
                                defaultChecked={
                                  this.state.firnished == 0 ? true : false
                                }
                                onClick={this.handleOnUnFurnished}
                              />
                              <label htmlFor="radio2">
                                {i18next.t("unfurnished")}
                              </label>
                            </div>
                          </div>
                          <div  className={this.state.purpose==1?'mb-3 d-none':'mb-3 d-block'}>
                            <div className="wizard-form-radio">
                              <input
                                name="radio-name1"
                                id="radio1"
                                type="radio"
                                defaultChecked={
                                  this.state.offplanready == 0 ? true : false
                                }
                                onClick={this.handleOffplan}
                              />
                              <label htmlFor="radio1">
                                {i18next.t("offplan")}
                              </label>
                            </div>
                            &nbsp; &nbsp;
                            <div className="wizard-form-radio">
                              <input
                                name="radio-name1"
                                id="radio2"
                                type="radio"
                                defaultChecked={
                                  this.state.offplanready == 1 ? true : false
                                }
                                onClick={this.handleOffready}
                              />
                              <label htmlFor="radio2">
                                {i18next.t("ready")}
                              </label>
                            </div>
                          </div>
                        </div>
                          <h4>
                            {lang == "en"
                              ? "Location and Address"
                              : "العنوان علي الخريطة"}
                          </h4>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-2">
                                <Select
                                  placeholder={
                                    lang == "en"
                                      ? "ex: Emirate , Area , Building , street"
                                      : "الامارة ،المنطة ، المبنى ، الشارع"
                                  }
                                  className=" wizard-required"
                                  options={this.state.alllocations}
                                  onChange={(opt) => this.handleLocation(opt)}
                                  id="location_selected"
                                />
                              </div>
                              {/* <input type='text' id="area_selected" className="form-control wizard-required" /> */}
                            </div>
                          </div>
                          <div className="row" style={{ display: "none" }}>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                id="lat"
                              />
                            </div>
                            <div className="col-md-3">
                              <input
                                type="text"
                                className="form-control"
                                id="lng"
                              />
                            </div>
                          </div>
                          <div className="row mb-10">
                            <div className="col-md-12">
                              <div className="mb-5">
                                <section className="map-wrapper-container">
                                  <div className="map-wrapper">
                                    <div
                                      className="map-container"
                                      id="google-map"
                                    ></div>
                                    <Helmet>
                                      <script
                                        type="text/javascript"
                                        charset="UTF-8"
                                        async={true}
                                        defer={true}
                                        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCq6FzlBsSuT-S2zaRh2Rd-i4205DCu57s&callback=initMap&libraries=places"
                                      />
                                    </Helmet>
                                  </div>
                                </section>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control wizard-required"
                              id="title_en"
                              placeholder={i18next.t("title_en")}
                              onChange={this.handleTitleEn}
                              maxLength={65}
                              required
                            />
                          </div>

                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              id="title_ar"
                              placeholder={i18next.t("title_ar")}
                              onChange={this.handleTitleAr}
                              maxLength={65}
                            />
                          </div>

                          <div className="form-group">
                            <label>{i18next.t("description_en")}</label>
                            <JoditEditor
                              value={this.state.description_en}
                              config={config}
                              tabIndex={1}
                              onBlur={(newContent) =>
                                this.setState({ description_en: newContent })
                              }
                              onChange={(newContent) => {}}
                            />
                          </div>

                          <div className="form-group">
                            <label>{i18next.t("description_ar")}</label>
                            <JoditEditor
                              value={this.state.description_ar}
                              config={config}
                              tabIndex={1}
                              onBlur={(newContent) =>
                                this.setState({ description_ar: newContent })
                              }
                              onChange={(newContent) => {}}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control wizard-required"
                              id="area"
                              placeholder={i18next.t("area")}
                              onChange={this.handleSquarearea}
                            />
                          </div>
                          <div className="form-group">
                            <input
                              type="number"
                              className="form-control wizard-required"
                              id="price"
                              placeholder={i18next.t("price")}
                              onChange={this.handlePrice}
                            />
                          </div>

                          <div className={this.state.bathroomfeature}>
                            <div className="form-group">
                              <select
                                className="form-select form-select-lg mb-3"
                                style={{ fontSize: "unset", height: "2.75rem" }}
                                aria-label="form-select-lg example"
                                onChange={this.handleBathroom}
                                id="bathroom"
                              >
                                <option value="0">
                                  {lang == "en" ? "Bath Room" : "عدد الحمامات"}
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">20+</option>
                              </select>
                            </div>
                            <div className="form-group">
                              <select
                                className="form-select form-select-lg mb-3"
                                style={{ fontSize: "unset", height: "2.75rem" }}
                                aria-label="form-select-lg example"
                                onChange={this.handleBedroom}
                                id="bedroom"
                              >
                                <option value="-1">
                                  {lang == "en" ? "Bed Room" : " عدد الغرف"}
                                </option>
                                <option value="0">
                                  {lang == "en" ? "Studio" : "استوديو"}
                                </option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">20+</option>
                              </select>
                            </div>
                          </div>
                          <div className={this.state.landfeatures}>
                            <div className="mb-3">
                              <select
                                className="form-select form-select-lg mb-3"
                                style={{ fontSize: "unset", height: "2.75rem" }}
                                aria-label="form-select-lg example"
                                onChange={this.handleCompletionStatus}
                              >
                                <option value="0">
                                  {lang == "en"
                                    ? "Completion Status "
                                    : " حالة إكمال "}
                                </option>
                                <option value="1">
                                  {lang == "en" ? "Ready" : "  جاهز "}
                                </option>
                                <option value="2">
                                  {lang == "en" ? "Off Plan" : "  خارج الخطة "}
                                </option>
                              </select>
                            </div>
                            <div className="mb-3">
                              <select
                                className="form-select form-select-lg"
                                style={{ fontSize: "unset", height: "2.75rem" }}
                                aria-label="form-select-lg example"
                                onChange={this.handleOwnerStatus}
                              >
                                <option value="0">
                                  {lang == "en"
                                    ? "OwnerShip Status "
                                    : "  حالة الملكية  "}
                                </option>
                                <option value="1">
                                  {lang == "en" ? "Freshhold " : "   عقد جديد "}
                                </option>
                                <option value="2">
                                  {lang == "en"
                                    ? "Leasehold "
                                    : "   مسؤول المستأجرة "}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className={this.state.rentfileds}>
                            <h4>
                              {lang == "en" ? "Rent Details" : "تفاصيل الايجار"}
                            </h4>
                            <div className="col-lg-6">
                              <div className="mb-3">
                                <select
                                  className="form-select  wizard-required"
                                  style={{
                                    fontSize: "unset",
                                    height: "2.75rem",
                                  }}
                                  aria-label="form-select-lg example"
                                  onChange={this.handleRentFrequency}
                                >
                                  <option value="0">
                                    {i18next.t("rentfrequency")}
                                  </option>
                                  <option value="1">
                                    {lang == "en" ? "Yearly" : "سنوي"}
                                  </option>
                                  <option value="2">
                                    {lang == "en" ? "Monthly" : "شهري"}
                                  </option>
                                  <option value="3">
                                    {lang == "en" ? "Weekly" : "اسبوعي"}
                                  </option>
                                  <option value="4">
                                    {lang == "en" ? "Daily" : "يومي"}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={i18next.t("mincontract")}
                                  onChange={this.handleMinimumcontractperiod}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <select
                                  className="form-select form-select-lg"
                                  style={{
                                    fontSize: "unset",
                                    height: "2.75rem",
                                  }}
                                  aria-label="form-select-lg example"
                                  onChange={this.handlePaidby}
                                >
                                  <option value="0">
                                    {i18next.t("paidby")}
                                  </option>
                                  <option value="1">
                                    {lang == "en" ? "LandLord" : "المالك"}
                                  </option>
                                  <option value="2">
                                    {lang == "en" ? "Tenant" : "مستأجر"}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="mb-3">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder={i18next.t("mantianancefee")}
                                  onChange={this.handleMaintainaceFee}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 mb-2">
                              <div className="mb-2">
                                <select
                                  className="form-select form-select-lg"
                                  style={{
                                    fontSize: "unset",
                                    height: "2.75rem",
                                  }}
                                  aria-label="form-select-lg example"
                                  onChange={this.handleVactingPeriod}
                                >
                                  <option value="0">
                                    {i18next.t("vacatingperiod")}{" "}
                                  </option>
                                  <option value="1">
                                    {lang == "en" ? "Day" : "يوم"}
                                  </option>
                                  <option value="2">
                                    {lang == "en" ? "Week" : "اسبوع"}
                                  </option>
                                  <option value="3">
                                    {lang == "en" ? "Month" : "شهر"}
                                  </option>
                                  <option value="4">
                                    {lang == "en" ? "Year" : "سنة"}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <input
                              type="number"
                              name="permitNumber"
                              className="form-control"
                              placeholder={i18next.t("permitnumber")}
                              onChange={this.handlePermitNumber}
                            />
                          </div>
                          <div className="form-group clearfix">
                            <div className="row">
                              <div className="col-md-10"></div>
                              <div className="col-md-2">
                                <a
                                  href="#"
                                  className="form-wizard-next-btn float-right rounded"
                                >
                                  {" "}
                                  {lang == "en" ? "Next" : "التالي"}{" "}
                                </a>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="wizard-fieldset">
                          <h5>{lang == "en" ? "Uploads" : "تحميل ملفات"}</h5>
                          <div
                            id="uploads"
                            className="tab-pane"
                            role="tabpanel"
                          >
                            <ul
                              className="nav nav-pills mb-3"
                              id="pills-tab"
                              role="tablist"
                            >
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link active"
                                  id="pills-home-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-image"
                                  role="tab"
                                  aria-controls="pills-home"
                                  aria-selected="true"
                                >
                                  {lang == "en" ? "Images" : "صور"}
                                </a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a
                                  className="nav-link"
                                  id="pills-profile-tab"
                                  data-bs-toggle="pill"
                                  data-bs-target="#pills-video"
                                  role="tab"
                                  aria-controls="pills-profile"
                                  aria-selected="false"
                                >
                                  {lang == "en" ? "Video" : "فيديو"}
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="pills-image"
                                role="tabpanel"
                                aria-labelledby="pills-home-tab"
                              >
                                <div style={{ marginBottom: "20px" }}>
                                  <label>
                                    {lang == "en"
                                      ? "Featured Image"
                                      : "صورة العقار"}
                                  </label>
                                  <input
                                    type="file"
                                    name="featured_image"
                                    onChange={this.handleFeaturedImage}
                                  />
                                  {this.state.featuredimage != null ? (
                                    <img
                                      src={this.state.imageShow}
                                      alt="tt"
                                      height="70px"
                                      width="90px"
                                    />
                                  ) : (
                                    <>
                                      {this.state.isMax ? (
                                        <span className="text-red">
                                          {lang == "en"
                                            ? "File too large"
                                            : "حجم الصورة كبير"}
                                        </span>
                                      ) : (
                                        <span className="text-red">
                                          {lang == "en"
                                            ? "Please upload an image smaller than 3MB"
                                            : "الرجاء رفع صورة اقل من 2 ميغا"}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                                <label>
                                  {lang == "en" ? "Other Images" : "صور اخرى"}
                                </label>
                                <input
                                  type="file"
                                  id="file"
                                  multiple
                                  name="file"
                                  onChange={this.handleChange}
                                />
                                <table className="table">
                                  <thead>
                                    {this.state.image.length > 0 ? (
                                      <tr>
                                        <th scope="col"> </th>

                                        <th scope="col"> </th>
                                      </tr>
                                    ) : null}
                                  </thead>
                                  <tbody>
                                    {this.state.image.length > 0 ? (
                                      this.state.image.map((file, index) => (
                                        <tr key={index}>
                                          <td>
                                            <img
                                              src={URL.createObjectURL(file)}
                                              alt="tt"
                                              height="70px"
                                              width="90px"
                                            />
                                          </td>

                                          <td>
                                            <a
                                              href="#"
                                              className="btn btn-sm btn-danger"
                                              onClick={this.removeFile.bind(
                                                this,
                                                file
                                              )}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                class="bi bi-x"
                                                viewBox="0 0 16 16"
                                              >
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                              </svg>
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <h6 className="text-danger text-center">
                                        {" "}
                                        {lang == "en"
                                          ? "no image found"
                                          : "لاتوجد صور"}
                                      </h6>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="pills-video"
                                role="tabpanel"
                                aria-labelledby="pills-profile-tab"
                              >
                                <table className="table" id="myTable">
                                  <thead>
                                    <tr>
                                      <th>
                                        {lang == "en"
                                          ? "Video Source"
                                          : "نوع الفيديو"}
                                      </th>
                                      <th>{lang == "en" ? "Link" : "رابط"}</th>
                                      <th>
                                        {lang == "en" ? "Title" : " العنوان"}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <select
                                          className="form-select"
                                          aria-label="Default select example"
                                          onChange={this.handleVideo_source}
                                        >
                                          <option value="0">
                                            {lang == "en"
                                              ? "Video Source"
                                              : "نوع الفيديو"}{" "}
                                          </option>
                                          <option value="1">Youtube</option>
                                          <option value="2">Vimeo</option>
                                        </select>
                                      </td>
                                      <td>
                                        <input
                                          aria-invalid="false"
                                          name="video_link"
                                          type="url"
                                          className="form-control"
                                          placeholder="Video Link"
                                          onChange={this.handleVideo_link}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          aria-invalid="false"
                                          name="video_title"
                                          type="url"
                                          className="form-control"
                                          placeholder="Video Title"
                                          onChange={this.handleVideo_title}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <div className="form-group clearfix">
                            <div className="row">
                              <div className="col-md-3">
                                <a
                                  href="javascript:;"
                                  className="form-wizard-previous-btn float-left rounded"
                                >
                                  {lang == "en" ? "Previous" : "السابق"}{" "}
                                </a>
                              </div>
                              <div className="col-md-6"></div>
                              <div className="col-md-3">
                                <a
                                  href="#"
                                  className="form-wizard-next-btn float-right rounded"
                                >
                                  {lang == "en" ? "Next" : "التالي"}
                                </a>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="wizard-fieldset">
                          <h5>{lang == "en" ? "Amenities" : "مميزات"}</h5>
                          <div
                            id="amenties"
                            className="tab-pane"
                            role="tabpanel"
                          >
                            <div className="row d-flex justify-content-center">
                              <div className="col col-sm-5">
                                <div className="quiz_content_area">
                                  <h1 className="quiz_title">
                                    {lang == "en" ? "Amenities" : "مميزات"}
                                  </h1>
                                  <div className="row">
                                    <h2 className="amenties-header">
                                      {lang == "en"
                                        ? "Health and Fitness"
                                        : "الصحة و اللياقة"}
                                    </h2>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.gym}
                                          onClick={this.handleGym.bind(this)}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-dumbbell"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "GYM"
                                                  : "نادي رياضي"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.swimmingpool}
                                          onClick={this.handleSwimmingbool.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-swimmer"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Swimming pool"
                                                  : "حوض سباحة"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.medical}
                                          onClick={this.handleMedical.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-clinic-medical"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Medical"
                                                  : "مركز طبي "}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.sauna_steem}
                                          onClick={this.handleSaunasteem.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-hot-tub"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />{" "}
                                                {lang == "en"
                                                  ? "Sauna/Steam"
                                                  : "ساونا /بخار"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.disability_access}
                                          onClick={this.handleDisabilityaccess.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-wheelchair"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Disability Access"
                                                  : "مناسب لأصحاب الهمم"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <h2 className="amenties-header">
                                      {lang == "en" ? "Features" : " سمات"}
                                    </h2>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.built_in_wardobes}
                                          onClick={this.handleBuiltinwardobes.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-door-closed"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Built in wardobes"
                                                  : "خزائن بالحائط"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.barbaque_area}
                                          onClick={this.handleBarbaquearea.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-dumpster-fire"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Barbaque area"
                                                  : "منطقة شواء"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.cafeteria}
                                          onClick={this.handleCafeteria.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-coffee"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Cafeteria"
                                                  : " كافتريا"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.kitchen}
                                          onClick={this.handleKitchen.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-sink"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Kitchen"
                                                  : " مطبخ"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.central_ac}
                                          onClick={this.handleCentralac.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-wind"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Central A/C"
                                                  : " تكيف مركزي"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.garden}
                                          onClick={this.handleGarden.bind(this)}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-tree"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Garden"
                                                  : " حديقة "}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.furnished}
                                          onClick={this.handleFurnished.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-couch"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Furnished"
                                                  : "مفروشة"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.disposal}
                                          onClick={this.handleDisposal.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-dumpster"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Disposal"
                                                  : "مقلب نفايات"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.kidsplay}
                                          onClick={this.handleKidsplay.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-skating"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Kids play"
                                                  : " منطقة لعب اطفال"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.parking}
                                          onClick={this.handleParking.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-parking"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Parking"
                                                  : "باركنج"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.balcony}
                                          onClick={this.handleBalcony.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-store"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Balcony"
                                                  : "بلكونه"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <h2 className="amenties-header">
                                      {lang == "en"
                                        ? "Miscellaneous"
                                        : " متنوع"}
                                    </h2>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.atm_acxeess}
                                          onClick={this.handleAtmacxeess.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-money-check"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "ATM Acxeess"
                                                  : "صراف الي"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.water_view}
                                          onClick={this.handleWaterview.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-water"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Water View"
                                                  : "واجهه بحر"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.landmark_view}
                                          onClick={this.handleLandmarkview.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-landmark"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Landmark view"
                                                  : "واجهه مميزه"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.day_care}
                                          onClick={this.handleDaycare.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-child"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? " Day care"
                                                  : "مركز رعايه اطفال "}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <h2 className="amenties-header">
                                      {lang == "en"
                                        ? "Security and Technology"
                                        : " الأمن والتكنولوجيا"}
                                    </h2>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.security_guard}
                                          onClick={this.handleSecurityguard.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-user-secret"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? " Security Guard "
                                                  : "حارس امن"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.satelite_cable_tv}
                                          onClick={this.handleSatelitecabletv.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-tv"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Satelite/Cable TV"
                                                  : " ستالايت"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.cctv}
                                          onClick={this.handleCctv.bind(this)}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-video"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "cctv"
                                                  : " كاميرات مراقبة"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col">
                                      <div className="quiz_card_area">
                                        <input
                                          className="quiz_checkbox"
                                          type="checkbox"
                                          checked={this.state.internet}
                                          onClick={this.handleInternet.bind(
                                            this
                                          )}
                                        />
                                        <div className="single_quiz_card">
                                          <div className="quiz_card_content">
                                            <div className="quiz_card_icon">
                                              <i className="fa fa-network-wired"></i>
                                            </div>
                                            <div className="quiz_card_title">
                                              <h3>
                                                <i
                                                  className="fa fa-check"
                                                  aria-hidden="true"
                                                />
                                                {lang == "en"
                                                  ? "Internet"
                                                  : "انترنت"}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group clearfix ">
                            <div className="row">
                              <div className="col-md-3">
                                <a
                                  href="javascript:;"
                                  className="form-wizard-previous-btn rounded"
                                >
                                  {lang == "en" ? "Previous" : "السابق"}
                                </a>
                              </div>
                              <div className="col-md-6"></div>
                              <div className="col-md-3">
                                {this.buttonRender()}
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default AgentProperty;
